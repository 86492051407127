import React, { Component } from 'react';
import LetsTalk from './LetsTalk';
import { ReactComponent as Image } from '../assets/faq.svg';
import { ReactComponent as Arrow } from '../assets/dropdown.svg';
import style from '../css/faq.module.scss';
import 'array.prototype.fill';

function Faq() {
	return (
		<div>
			<FrequentlyAskedQuestions />
			<Topics />
			<LetsTalk />
		</div>
	);
}

function FrequentlyAskedQuestions() {
	return (
		<div className={style.container1}>
			<div className={style.faqContainer}>
				<Image />
				<h1 className={style.header}>Frequently Asked Questions</h1>
			</div>
			<div className={style.text}>
				<p className={style.paragraph}>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
					incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
					nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
					Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.
				</p>
				<p className={style.paragraph}>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
					incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
					nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
					Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.
				</p>
			</div>
		</div>
	);
}

class Topics extends Component {
	constructor(props) {
		super(props);
		this.state = {
			faqCategory: 'FAQ Category #1',
			questionOpen: Array(6).fill(false),
			dropdownOpen: false
		};
	}

	openQuestion = question => {
		this.setState(prevState => ({
			questionOpen: prevState.questionOpen.map(
				(value, index) => (index === question ? !value : value)
			)
		}));
	};

	chooseCategory = category => {
		this.setState({
			questionOpen: Array(6).fill(false),
			faqCategory: category,
			dropdownOpen: false
		});
	};

	openDropDown = () => {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	};

	renderFaqCategory = category => {
		if (category === 'All questions') {
			return (
				<div>
					<FAQCategory1
						questionOpen={this.state.questionOpen}
						openQuestion={this.openQuestion}
					/>
					<FAQCategory2
						questionOpen={this.state.questionOpen}
						openQuestion={this.openQuestion}
					/>
					<FAQCategory3
						questionOpen={this.state.questionOpen}
						openQuestion={this.openQuestion}
					/>
					<FAQCategory4
						questionOpen={this.state.questionOpen}
						openQuestion={this.openQuestion}
					/>
				</div>
			);
		}
		if (category === 'FAQ Category #1') {
			return (
				<FAQCategory1
					questionOpen={this.state.questionOpen}
					openQuestion={this.openQuestion}
				/>
			);
		}
		if (category === 'FAQ Category #2') {
			return (
				<FAQCategory2
					questionOpen={this.state.questionOpen}
					openQuestion={this.openQuestion}
				/>
			);
		}
		if (category === 'FAQ Category #3') {
			return (
				<FAQCategory3
					questionOpen={this.state.questionOpen}
					openQuestion={this.openQuestion}
				/>
			);
		}
		if (category === 'FAQ Category #4') {
			return (
				<FAQCategory4
					questionOpen={this.state.questionOpen}
					openQuestion={this.openQuestion}
				/>
			);
		}
		return null;
	};

	render() {
		return (
			<div className={style.container2}>
				<div className={style.first}>
					<div className={style.dropDownContainer}>
						<h2 className={style.littleHeader}>Choose topic</h2>
						<div className={style.categoryDropDown}>
							<p onClick={() => this.openDropDown()}>{this.state.faqCategory}</p>
							{this.state.dropdownOpen ? (
								<div className={style.dropDown}>
									{this.state.faqCategory === 'All questions' ? null : (
										<p onClick={() => this.chooseCategory('All questions')}>
											All questions
										</p>
									)}
									{this.state.faqCategory === 'FAQ Category #1' ? null : (
										<p onClick={() => this.chooseCategory('FAQ Category #1')}>
											FAQ Category #1
										</p>
									)}
									{this.state.faqCategory === 'FAQ Category #2' ? null : (
										<p onClick={() => this.chooseCategory('FAQ Category #2')}>
											FAQ Category #2
										</p>
									)}
									{this.state.faqCategory === 'FAQ Category #3' ? null : (
										<p onClick={() => this.chooseCategory('FAQ Category #3')}>
											FAQ Category #3
										</p>
									)}
									{this.state.faqCategory === 'FAQ Category #4' ? null : (
										<p onClick={() => this.chooseCategory('FAQ Category #4')}>
											FAQ Category #4
										</p>
									)}
								</div>
							) : null}
						</div>
					</div>
					<div className={style.category}>
						<p onClick={() => this.chooseCategory('All questions')}>All questions</p>
						<p onClick={() => this.chooseCategory('FAQ Category #1')}>
							FAQ Category #1
						</p>
						<p onClick={() => this.chooseCategory('FAQ Category #2')}>
							FAQ Category #2
						</p>
						<p onClick={() => this.chooseCategory('FAQ Category #3')}>
							FAQ Category #3
						</p>
						<p onClick={() => this.chooseCategory('FAQ Category #4')}>
							FAQ Category #4
						</p>
					</div>
				</div>
				<div className={style.second}>{this.renderFaqCategory(this.state.faqCategory)}</div>
			</div>
		);
	}
}

function FAQCategory1(props) {
	return (
		<div>
			<h2 className={style.littleHeader}>FAQ Category #1</h2>
			<div className={style.questions} id="questions">
				<div className={style.button} onClick={() => props.openQuestion(0)}>
					<p>Irure dolor in reprehenderit in voluptate velit esse cillum dolore?</p>
					<Arrow />
				</div>
				{props.questionOpen[0] ? (
					<div onClick={() => props.openQuestion(0)}>
						<h2>
							Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
							dolore?
						</h2>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
							tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
							veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
							commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
							velit esse cillum dolore. Ut enim ad minim veniam, quis nostrud
							exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
							Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
							dolore.
						</p>
					</div>
				) : null}
				<div className={style.button} onClick={() => props.openQuestion(1)}>
					<p>Dolor in reprehenderit in voluptate velit esse cillum dolore?</p>
					<Arrow />
				</div>
				{props.questionOpen[1] ? (
					<div onClick={() => props.openQuestion(1)}>
						<h2>
							Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
							dolore?
						</h2>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
							tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
							veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
							commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
							velit esse cillum dolore. Ut enim ad minim veniam, quis nostrud
							exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
							Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
							dolore.
						</p>
					</div>
				) : null}
				<div className={style.button} onClick={() => props.openQuestion(2)}>
					<p>Reprehenderit in voluptate velit esse cillum dolore?</p>
					<Arrow />
				</div>
				{props.questionOpen[2] ? (
					<div onClick={() => props.openQuestion(2)}>
						<h2>
							Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
							dolore?
						</h2>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
							tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
							veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
							commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
							velit esse cillum dolore. Ut enim ad minim veniam, quis nostrud
							exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
							Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
							dolore.
						</p>
					</div>
				) : null}
				<div className={style.button} onClick={() => props.openQuestion(3)}>
					<p>Voluptate velit esse cillum dolore?</p>
					<Arrow />
				</div>
				{props.questionOpen[3] ? (
					<div onClick={() => props.openQuestion(3)}>
						<h2>
							Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
							dolore?
						</h2>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
							tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
							veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
							commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
							velit esse cillum dolore. Ut enim ad minim veniam, quis nostrud
							exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
							Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
							dolore.
						</p>
					</div>
				) : null}
				<div className={style.button} onClick={() => props.openQuestion(4)}>
					<p>In reprehenderit in voluptate velit esse cillum dolore?</p>
					<Arrow />
				</div>
				{props.questionOpen[4] ? (
					<div onClick={() => props.openQuestion(4)}>
						<h2>
							Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
							dolore?
						</h2>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
							tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
							veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
							commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
							velit esse cillum dolore. Ut enim ad minim veniam, quis nostrud
							exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
							Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
							dolore.
						</p>
					</div>
				) : null}
				<div className={style.button} onClick={() => props.openQuestion(5)}>
					<p>Dolor in reprehenderit in voluptate velit esse cillum dolore?</p>
					<Arrow />
				</div>
				{props.questionOpen[5] ? (
					<div onClick={() => props.openQuestion(5)}>
						<h2>
							Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
							dolore?
						</h2>
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
							tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
							veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
							commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
							velit esse cillum dolore. Ut enim ad minim veniam, quis nostrud
							exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
							Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
							dolore.
						</p>
					</div>
				) : null}
			</div>
		</div>
	);
}

function FAQCategory2(props) {
	return (
		<div>
			<h2 className={style.littleHeader}>FAQ Category #2</h2>
		</div>
	);
}

function FAQCategory3(props) {
	return (
		<div>
			<h2 className={style.littleHeader}>FAQ Category #3</h2>
		</div>
	);
}

function FAQCategory4(props) {
	return (
		<div>
			<h2 className={style.littleHeader}>FAQ Category #4</h2>
		</div>
	);
}

export default Faq;
