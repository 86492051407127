import React from 'react';
import blog from '../css/blogPreview.module.scss';
import { ReactComponent as ArrowLeft } from '../assets/arrow-left.svg';
import BlogPostPreview from './BlogPost';
import BlogPostOnePreview from './BlogPostOne';
import BlogPostTwoPreview from './BlogPostTwo';
import BlogPostThreePreview from './BlogPostThree';
import BlogPostFourPreview from './BlogPostFour';


import { ReactComponent as ArrowRight } from '../assets/arrow-right.svg';

function Blog() {
	return (
		<div className={blog.blog}>
			<div className={blog.container}>
				{/*<div className={blog.arrowLeft}><ArrowLeft /></div>*/}
				<div className={blog.post1}>
					<BlogPostFourPreview />
				</div>
				<div className={blog.post2}>
					<BlogPostTwoPreview />
				</div>
				<div className={blog.post3}>
					<BlogPostThreePreview />
				</div>
				{/*<div className={blog.arrowRight}><ArrowRight /></div>*/}
			</div>
		</div>
	);
}

export default Blog;
