import React from 'react';
import {Link} from "react-router-dom";
import blog from '../css/blogPreview.module.scss';

function BlogPostPreview() {
	return (
		<div className={blog.hover}>
			<div className={blog.blogPostContainer}>
			<Link to="/blog/five-reasons-to-start-offshore-company">
				 <img src={require('./../assets/Offshore-company-small.jpg')} height='100%' width='100%' align="middle"/>
				{/*<p className={blog.paragraph}>Image</p>*/}
				<h2 className={blog.littleHeader}>Five reasons to start offshore company</h2>
				<p className={blog.paragraph}>
					It is not only about tax benefits and privacy. Offshore companies have benefits you might have never thought about.
				</p></Link>
			</div>
		</div>
	);
}
export default BlogPostPreview;
