import React, { Component } from 'react';
import './App.css';

import { addLocaleData, IntlProvider } from 'react-intl';
import localeEn from 'react-intl/locale-data/en';
import localeLt from 'react-intl/locale-data/lt';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import messagesLt from './translations/lt.json';
import messagesEn from './translations/en.json';

import Navigation from './navigation/Navigation';
import Footer from './navigation/Footer';

import MainPage from './main/MainPage';
import AboutUs from './main/AboutUs';
import Services from './main/Services';
import Blog from './main/Blog';
import Contact from './main/Contact';
import Privacy from './main/Privacy';
import CookiePolicy from './main/CookiePolicy';
import TermsAndConditions from './main/TermsAndConditions';
import Faq from './main/Faq';
import Anguilla from './main/ServiceForCountry';
import Post from './main/Post';
import PostOne from './main/PostOne';
import PostTwo from './main/PostTwo';
import PostThree from './main/PostThree';
import PostFour from './main/PostFour';

import PersonalBankAccountOpening from './main/PersonalBankAccountOpening';
import CorporateBankAccountOpening from './main/CorporateBankAccountOpening';
import BankAccountsForOffshoreCompanies from './main/BankAccountsForOffshoreCompanies';
import CompanyIncorporation from './main/CompanyIncorporation';
import SecondCitizenship from './main/SecondCitizenship';

import './css/app.css';

const messages = {
	lt: messagesLt,
	en: messagesEn
};

addLocaleData([...localeEn, ...localeLt]);

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			language: 'en'
		};
	}

	switchLanguage = lang => {
		this.setState({
			language: lang
		});
	};

	render() {
		return (
			<div>
				<IntlProvider locale={this.state.language} messages={messages[this.state.language]}>
				<BrowserRouter>
					<div className="App">
						<Navigation
							switchLanguage={this.switchLanguage}
							languageSelected={this.state.language}
						/>
						<Main />
						<Footer />
					</div>
					</BrowserRouter>
				</IntlProvider>
			</div>
		);
	}
}

function Main() {
	return (
		<Switch>
			<Route exact path="/" component={MainPage} />
			<Route path="/aboutUs" component={AboutUs} />
			<Route exact path="/services" component={Services} />
			<Route exact path="/blog" component={Blog} />
			<Route path="/contact" component={Contact} />
			<Route path="/privacy" component={Privacy} />
			<Route path="/cookies" component={CookiePolicy} />
			<Route path="/termsAndConditions" component={TermsAndConditions} />
			<Route path="/faq" component={Faq} />
			{/* path='services/country' component = {serviceForCountry(country)} */}
			<Route path="/services/Anguilla" component={Anguilla} />
			<Route path="/blog/post" component={Post} />
			<Route path="/blog/why-i-decided-to-start-this-business" component={PostOne} />
			<Route path="/blog/why-you-might-need-an-offshore-bank-account" component={PostTwo} />
			<Route path="/blog/why-you-get-rejected-by-banks" component={PostThree} />
			<Route path="/blog/five-reasons-to-start-offshore-company" component={PostFour} />

			<Route path="/personal-bank-account-opening" component={PersonalBankAccountOpening} />
			<Route path="/business-bank-account-opening" component={CorporateBankAccountOpening} />
			<Route path="/bank-account-opening-for-offshore-companies" component={BankAccountsForOffshoreCompanies} />
			<Route path="/company-formation" component={CompanyIncorporation} />
			<Route path="/second-citizenship" component={SecondCitizenship} />


		</Switch>
	);
}

export default App;
