import React from 'react';
import {Link} from "react-router-dom";
import blog from '../css/blogPreview.module.scss';

function BlogPostPreview() {
	return (
		<div className={blog.hover}>
			<div className={blog.blogPostContainer}>
			<Link to="/blog/why-i-decided-to-start-this-business">
				 <img src={require('./../assets/Stairs-to-tax-heaven-small.jpg')} height='100%' width='100%' align="middle"/>
				{/*<p className={blog.paragraph}>Image</p>*/}
				<h2 className={blog.littleHeader}>Why I decided to start this business</h2>
				<p className={blog.paragraph}>
					A story about how I ended up navigating people and businesses in the offshore world.
				</p></Link>
			</div>
		</div>
	);
}
export default BlogPostPreview;
