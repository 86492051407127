import React from 'react';
import LetsTalk from './LetsTalk';
import style from '../css/service.module.scss';
import { ReactComponent as SafeImage } from '../assets/bankaccounts.svg';
import { ReactComponent as PaymentCardsImage } from '../assets/initialcoinoffer.svg';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";


function Services() {
	return (
		<div>
		<div className="application">
		<Helmet>
				<meta charSet="utf-8" />
				<title>Personal Bank Accounts | Offshore Bank Accounts</title>
				<meta name="description" content="Open a personal offshore bank account. Individual bank account solutions including crypto friendly accounts, investment accounts and residency accounts." />
				<link rel="canonical" href="https://offshorebankaccounts.com" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Offshore Bank Accounts" />
        <meta property="og:description" content="Opening offshore bank accounts for businesses and individuals" />
        <meta property="og:url" content="https://offshorebankaccounts.com" />
        <meta property="og:site_name" content="Offshore Bank Accounts" />
        <meta property="og:image" content="https://offshorebankaccounts.com/assets/offshore-bank-og.png" />
        <meta property="og:image:secure_url" content="https://offshorebankaccounts.com/assets/offshore-bank-og.png" />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="720" />
		</Helmet>
		</div>
			<BankAccountOpening />
			<PartnerBanks />
			<LetsTalk />
		</div>
	);
}

function BankAccountOpening() {
	return (
		<div className={style.mainContainer}>
			<div className={style.headerText}>
				<h1 className={style.header}>Personal Bank Account Opening</h1>
				<SafeImage />
				{/*<button>Order now</button>*/}
			</div>
			<div className={style.text}>
				<p className={style.paragraph}>
					There is a number of reasons why individuals want to open foreign bank accounts. These reasons include diversification, assets protection or access to new financial markets and instruments. The rule of not keeping all eggs in one basket is a rule of thumb in financial world, hence it is smart to keep your money and investments in different jurisdictions to diversify political risk. Also for better investments diversification it is important to have exposure to different markets, hence foreign banks are able to offer an exposure to their niche markets.
				</p>
				<p className={style.paragraph}>
					While most banks are focused for local clients, the best solution for international people an account at an offshore banks. As they specialize in dealing with international clients they can offer wide range of solutions to fit their needs. Furthermore, because of the favorable fiscal climate they are less expensive than alternatives as they have a lower cost base.
				</p>
			</div>
		</div>
	);
}
function PartnerBanks() {
	return (
		<div className={style.servicesContainer}>
			<div className={style.firstColumn}>
				{/*<h2 className={style.littleHeader}>Our partner banks</h2>
				<ul className={style.countries}>
					<Link to="services/Anguilla">
						<li>Anguilla</li>
					</Link>
					<li> Belize</li>
					<li> British virgin Islands</li>
					<li> Dominica </li>
					<li>Duai</li> <li>Gibraltar</li>
					<li> Hong Kong</li>
					<li> Latvia</li>
					<li> Liechtenstein</li>
					<li> Marshall Islands</li> <li>Panama </li>
					<li>Scotland</li>
					<li> Seychelles</li>
					<li> Switzerland</li>
				</ul>*/}
			</div>
			<div className={style.secondColumn}>
				{/*<h2 className={style.littleHeader}>Price list</h2>
				<p className={style.paragraph}>
					Lipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
					incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
					nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
					Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.
				</p>
				<table className={style.table}>
					<tr>
						<td>Lipsum</td>
						<td>Lipsum dolor</td>
						<td>Lipsum dolor</td>
						<td>Lipsum dolor</td>
					</tr>
					<tr>
						<td>40</td>
						<td>Exercitation ullamco laboris</td>
						<td>Lipsum dolor</td>
						<td>Exercitation ullamco laboris</td>
					</tr>
					<tr>
						<td>40</td>
						<td>Ullamco laboris nisi ut aliquip ex</td>
						<td>Lipsum dolor</td>
						<td>Ullamco laboris nisi ut aliquip ex</td>
					</tr>
					<tr>
						<td>40</td>
						<td>Exercitation ullamco laboris</td>
						<td>Lipsum dolor</td>
						<td>Exercitation ullamco laboris</td>
					</tr>
					<tr>
						<td>40</td>
						<td>Ullamco laboris nisi ut aliquip ex</td>
						<td>Lipsum dolor</td>
						<td>Ullamco laboris nisi ut aliquip ex</td>
					</tr>
				</table>*/}
				<h2 className={style.littleHeader}>Our service includes </h2>
				<ul className={style.countries}>

					<li>- Recommendation of best-fit financial institutions for your needs, which are likely to accept you</li>
					<li>- Introduction to the bank or another financial institution</li>
					<li>- Assistance to prepare application and collect supporting documents to open an account</li>{' '}
					<li>- Support during on-going communication with a bank after account is open </li>
				</ul>
				<div className={style.warning}>
					{/* <img/> */}
					<p>Our prices for personal bank account opening starts from 250 EUR plus bank fees, however if your case is simple we might recommend you a suitable bank free of charge.</p>
				</div>
			</div>
		</div>
	);
}
function PaymentCards() {
	return (
		<div className={style.mainContainer}>
			<div className={style.headerText}>
				<h1 className={style.header}>Payment Cards</h1>
				<PaymentCardsImage />
				<button>Order now</button>
			</div>
			<div className={style.text}>
				<p className={style.paragraph}>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
					incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
					nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
					Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.
				</p>
				<p className={style.paragraph}>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
					incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
					nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
					Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.
				</p>
			</div>
		</div>
	);
}
function PartnerBanksShort() {
	return (
		<div className={style.servicesContainer}>
			<div className={style.firstColumn}>
				<h2 className={style.littleHeader}>Our partner banks</h2>
				<ul className={style.countries}>
					<li>Anguilla</li>
					<li> Belize</li>
					<li> British virgin Islands</li>
					<li> Dominica </li>
					<li>Duai</li> <li>Gibraltar</li>
					<li> Hong Kong</li>
					<li> Latvia</li>
					<li> Liechtenstein</li>
					<li> Marshall Islands</li> <li>Panama </li>
					<li>Scotland</li>
					<li> Seychelles</li>
					<li> Switzerland</li>
				</ul>
			</div>
			<div className={style.secondColumn}>
				<h2 className={style.littleHeader}>Dolor sit amet </h2>
				<ul className={style.countries}>
					<li>- Highest security standards</li> <li>- No credit score </li>
					<li>- Strict banking secrecy</li>{' '}
					<li>- Account currency optionally in EUR, USD, GBP, CHF or YEN </li>
					<li>- Direct bank transfer or via correspondent banks per IBAN and BIC </li>
					<li>- Asset management and diversified portfolio management</li>{' '}
					<li>- Direct Access to the worldwide SWIFT system</li>{' '}
					<li>- Access to online and phone banking</li> <li>- Debit and credit cards</li>
				</ul>
				<div className={style.warning}>
					{/* <img/> */}
					<p>Please also see the restrictions of this service.</p>
				</div>
			</div>
		</div>
	);
}

export default Services;
