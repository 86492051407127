import React from 'react';
import LetsTalk from './LetsTalk';
import Blog from "./BlogPreview";
import style from '../css/blogPost.module.scss';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";


function Post() {
	return (
		<div>
		<div className="application">
		<Helmet>
				<meta charSet="utf-8" />
				<title>Offshore Bank Accounts</title>
				<meta name="description" content="Opening offshore bank accounts and helping offshore companies to get a bank account. Also assisting with legal structuring and second citizenships." />
				<link rel="canonical" href="https://offshorebankaccounts.com" />
				<meta property="og:locale" content="en_US" />
				<meta property="og:type" content="website" />
				<meta property="og:title" content="Offshore Bank Accounts" />
				<meta property="og:description" content="Opening offshore bank accounts for businesses and individuals" />
				<meta property="og:url" content="https://offshorebankaccounts.com" />
				<meta property="og:site_name" content="Offshore Bank Accounts" />
				<meta property="og:image" content="https://offshorebankaccounts.com/assets/offshore-bank-og.png" />
				<meta property="og:image:secure_url" content="https://offshorebankaccounts.com/assets/offshore-bank-og.png" />
				<meta property="og:image:width" content="1280" />
				<meta property="og:image:height" content="720" />
		</Helmet>
		</div>
			<div className={style.headerImagePostTwo}></div>
			<div className={style.container}>
				<div className={style.headerContainer}>
					<div className={style.header1}>
						<h1 className={style.header}>Why you might need an offshore bank account?</h1>
						<p className={style.author}>Gediminas Butkus / 2020 02 25</p>
					</div>
					<div className={style.header2}>
						{/*<button className={style.shareButton}>Share This Post</button>*/}
						<Link to='/blog'>
						<button className={style.backButton}><p>Back to Blog</p></button>
						</Link>
					</div>
				</div>
				<div>
					<div className={style.text}>
	{/*					<h2 className={style.littleHeader}>

						</h2>*/}
						<p className={style.paragraph}>
If you’re not familiar with the whole concept of offshore banking, the word “offshore” might bring about negative connotations or associate with tax evasion and other illicit activity. That’s mainly due to the fact that the media has been portraying offshore banking in a bad light, namely because of several isolated scandals. But what makes for compelling headlines is not always true.
						</p>
						<p className={style.paragraph}>
Let me be straight with you — offshore banking is nothing like the things I’ve just mentioned. To put it simply, it’s banking that’s done in the land that is not your own.  So let’s remove all the negative associations with the term and get it straight what offshore banking really is (and is not) once and for all.
						</p>
						<h2 className={style.littleHeader}>The many reasons why people opt for offshore bank accounts</h2>
						<p className={style.paragraph}>
One of the most common reasons why people open offshore bank accounts is asset protection. Depending on the country you live in, you might be looking for more privacy and want to protect your wealth from being affected by the unstable local financial system, political turmoil or from getting frozen by bad actors.
						</p>
						<p className={style.paragraph}>
Alternatively, you might have been working very hard your whole life to secure enough wealth for your future generations and hence are looking for a more tax-friendly environment to hold your assets in. In some countries, persons and businesses are taxed on what they bring onshore, so gains from international businesses don’t count. What’s more, offshore account services are often less expensive.
						</p>
						<p className={style.paragraph}>
In addition to the perks that I’ve just mentioned, the majority of the offshore domiciles are much more flexible than the high street banks. They offer significantly less bureaucracy, they have much better customer support, and they usually have their own compliance teams who are dedicated to working on complicated cases. Offshore financial institutions are willing to listen to your complex situations, whereas onshore banks do not have sufficient human resources to address such individual cases.
						</p>
						<p className={style.paragraph}>
Finally, most of the onshore banks are focused on local businesses, while offshore banks are more involved with international ones. Personally, as a businessman with a global mindset who has worked with numerous companies across the world, I find the offshore banks easier to communicate with.
						</p>
						<p className={style.paragraph}>
						Just as before, I’ll wrap up by providing real-life examples that illustrate the points I’ve laid out in this blog post.
						</p>
						<h2 className={style.littleHeader}>Case #1 from the public</h2>
						<p className={style.paragraph}>
						<strong>Question:</strong> I have been declaring my profit as a capital gain for the past 3 years. That way it's 50% less taxed in Quebec/Canada but it's still way too much. I was born in Canada and live there permanently. According to the research, I have done what I am looking for is a kind of holding company, a way to drastically reduce the taxes I would have to pay and perhaps lease vehicles and invest in real estate with that company. I am using two brokers for the moment, an offshore one that pays the profits via BTC, a company/bank account that is crypto-friendly is a plus. The second broker would have to be registered to the due company we're talking about. Companies formation and all these things are not my cups of tea, let me know what do you have in mind regarding this.
						</p>
						<p className={style.paragraph}>
						<strong>Our answer:</strong> There are plenty of jurisdictions that offer low or even zero taxation for income received by the companies established in these jurisdictions. Certain countries do not have a corporate income tax at all (offshore islands) or apply 0% corporate income tax rate (United Arab Emirates). Some other countries have special tax incentives that allow applying small corporate income tax rates (for example, Cyprus allows to reduce the corporate income tax rate to 2.5%, Malta to 5%). If the properly structured, profit of Dutch partnerships is exempt from tax.
						</p>
						<p className={style.paragraph}>
						So I am trying to say that there are a lot of different tax structuring options. The main aspects which need to be considered are the Controlled Foreign Corporation (CFC) rule in Canada and the “Permanent Establishment” (PE) concept. Under CFC rule your local tax authorities might try to tax income of the foreign company as if it was your personal income. Under PE concept your local tax authorities might try to tax income of the foreign company in Canada as it was income of “deemed” Canadian company. Special tax planning techniques help to mitigate these risks. So any structure you will choose should take into consideration all circumstances in order to avoid negative consequences and find the best option for you.
						</p>
						<h2 className={style.littleHeader}>Case #2 from the public</h2>
						<p className={style.paragraph}>
						<strong>Question:</strong> I currently have a UK ltd and I'm not a fan of having to pay tax on every profit, even if it's not distributed. I keep the most profit in the company. How does the Estonian system differ from this? And what would be the appropriate method to redomicile the UK company to Estonia and how much would it cost?
						</p>
						<p className={style.paragraph}>
						<strong>Our answer:</strong> TEstonia does not tax the profits of the company as long as they are not distributed, i.e. reinvested profit is not subject to tax. But when profit is distributed, Estonia applies the following taxes: (i) profit tax and (ii) dividend tax. An Estonian company might work in your case only if you are not planning to withdraw money in the form of dividends. As a general rule, Estonian tax authorities examine all expenses of the company very carefully to prevent taking money from the country without taxation. I have clients who came to Estonia a long time ago, made good profits, but can’t find a way to get out of there without paying taxes.
						</p>
						<p className={style.paragraph}>
						In your case, I would be looking for other solutions. For example, a Cyprus company might benefit from special tax incentives and pay only 2.5% corporate income tax rate in some instances. Maltese companies are subject to an effective tax rate of 5%. The United Arab Emirates does not apply corporate income tax at all. All these countries do not tax dividends paid to the shareholder. However, dividends would be taxed in the UK under domestic rules. You could take a part of the money as salaries in these countries and depending on the circumstances salaries would be taxed at a low rate (in UAE – 0%). Under UK and Cyprus, Malta and UAE treaty for the avoidance of double taxation, the UK does not have any right to tax these salaries in the country.
						</p>
					</div>
					{/*<div className={style.postImage}></div>
					<div className={style.text}>
						<p className={style.paragraph}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
							tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
							veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
							commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
							velit esse cillum dolore. Lorem ipsum dolor sit amet, consectetur
							adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
							magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
							laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
							in reprehenderit in voluptate velit esse cillum dolore.{' '}
						</p>
						<p className={style.paragraph}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
							tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
							veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
							commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
							velit esse cillum dolore. Lorem ipsum dolor sit amet, consectetur
							adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
							magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
							laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
							in reprehenderit in voluptate velit esse cillum dolore. Lorem ipsum
							dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
							incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
							quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
							consequat. Duis aute irure dolor in reprehenderit in voluptate velit
							esse cillum dolore. Lorem ipsum dolor sit amet, consectetur adipiscing
							elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
							Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
							ut aliquip ex ea commodo consequat. Duis aute irure dolor in
							reprehenderit in voluptate velit esse cillum dolore.
						</p>
					</div>*/}
				</div>
			</div>
			<h1 className={style.header}>Also you may like this</h1>
			<Blog />
			<LetsTalk />
		</div>
	);
}

export default Post;
