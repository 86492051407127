import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as BankImage } from '../assets/offshorebank.svg';
import style from '../css/main.module.scss';
import about from '../css/mainAbout.module.scss';
import LetsTalk from './LetsTalk';
import { ReactComponent as BankAccounts } from '../assets/bankaccounts.svg';
import { ReactComponent as PaymentCards } from '../assets/paymentcards.svg';
import { ReactComponent as CompanyIncorporation } from '../assets/aboutus.svg';
import { ReactComponent as Citizenship } from '../assets/citizenship.svg';
import { ReactComponent as InitialCoinOfferings } from '../assets/initialcoinoffer.svg';
import services from '../css/discoverOurServices.module.scss';
import Blog from './BlogPreview';
import {Helmet} from "react-helmet";


function MainPage() {
	return (
		<div className={style.page}>
		<div className="application">
		<Helmet>
				<meta charSet="utf-8" />
				<title>Offshore Bank Accounts</title>
				<meta name="description" content="Opening offshore bank accounts and helping offshore companies to get a bank account. Also assisting with legal structuring and second citizenships." />
				<link rel="canonical" href="https://offshorebankaccounts.com" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Offshore Bank Accounts" />
        <meta property="og:description" content="Opening offshore bank accounts for businesses and individuals" />
        <meta property="og:url" content="https://offshorebankaccounts.com" />
        <meta property="og:site_name" content="Offshore Bank Accounts" />
        <meta property="og:image" content="https://offshorebankaccounts.com/media/offshore-bank-og.png" />
        <meta property="og:image:secure_url" content="https://offshorebankaccounts.com/media/offshore-bank-og.png" />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="720" />
		</Helmet>
		</div>
			<MainView />
		{/*	<AboutUs />*/}
			<DiscoverServices />
			<BlogPreview />
			<LetsTalk />
		</div>
	);
}

function MainView() {
	return (
		<div className={style.container}>
			<div className={style.bankImage}>
				<BankImage />
			</div>
			<div className={style.text}>
				<h1 className={style.header}>Helping to open offshore bank accounts</h1>
				<p className={style.paragraph}>
					Given our experience and good relationships with various financial institutions around the globe, we can not only introduce you to the best financial institution for your needs but also lead you through the process of opening a bank account.				</p>
				<Link to="/contact"><button className={style.button}>Contact us</button></Link>
			</div>
		</div>
	);
}

function AboutUs() {
	return (
		<div className={about.container}>
			<div className={about.containerAboutUs}>
				<h1 className={about.header}>Solutions</h1>
			</div>
			<div className={about.text}>
				<div>
					<h2 className={about.littleHeader}>Personal bank accounts</h2>
					<p className={about.paragraph}>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
						tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
						quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
						consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
						cillum dolore.
					</p>
				</div>
				<div>
					<h2 className={about.littleHeader}>Corporate bank accounts</h2>
					<p className={about.paragraph}>
						Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
						eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
						sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum
						dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
						incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
						nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
						consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
						cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
						non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
					</p>
				</div>
			</div>
		</div>
	);
}

function DiscoverServices() {
	return (
		<div id="solutions" className={services.container}>
			<div className={services.headerCointainer}>
				<h1 className={services.header}>Solutions</h1>
			</div>
			<div className={services.whiteContainer}>
				<div className={services.image}>
					<BankAccounts />
				</div>
				<Link to="/personal-bank-account-opening">
				<h2 className={services.littleHeader}>Personal Bank Account Opening</h2>
				<p className={services.paragraph}>
					If you need a bank account for your individual activity or just as safe-heaven to diversify your assets, we can help you to open a personal bank account in several banks, both offshore and onshore.
		</p></Link>
		</div>
			<div className={services.creamContainer}>
				<div className={services.image}>
					<CompanyIncorporation />
				</div>
				<Link to="/business-bank-account-opening">
				<h2 className={services.littleHeader}>Business Bank Account Opening</h2>
				<p className={services.paragraph}>
					Sometimes the crucial part of starting the business is to open a corporate bank account. We can help you to find a bank willing to understand your business model. {/*and build a long-lasting relationship with you and your business.*/}
				</p>
				</Link>
			</div>

			<div className={services.whiteContainer2}>
				<div className={services.image}>
					<BankAccounts />
				</div>
				<Link to="/bank-account-opening-for-offshore-companies">
				<h2 className={services.littleHeader}>Bank Accounts for Offshore Companies</h2>
				<p className={services.paragraph}>
				It can be a challenge to open a bank account for offshore companies. Luckily, we can introduce you to several banks willing to onboard you and help you until the bank account is operational.
				</p>
				</Link>
			</div>
			<div className={services.creamContainer2}>
				<div className={services.image}>
					<CompanyIncorporation />
				</div>
				<Link to="/company-formation">
				<h2 className={services.littleHeader}>Company Registration</h2>
				<p className={services.paragraph}>
				After considering your needs, we can recommend you the best solution for your case and establish a company or set up a whole corporate structure.
				</p>
				</Link>
			</div>
			<div className={services.whiteContainer3}>
				<div className={services.image}>
					<Citizenship />
				</div>
				<Link to="/second-citizenship">
				<h2 className={services.littleHeader}>Second Citizenship</h2>
				<p className={services.paragraph}>
					If you need a second passport to travel visa-free or as an insurance policy for a rainy day, we can advise you on the best solutions for your particular case and help you to obtain a second citizenship.
				</p>
				</Link>
			</div>
		</div>
	);
}

function BlogPreview() {
	return (
		<div>
			<h1 className={style.littleHeader}>Blog</h1>
			<Blog />
		</div>
	);
}


export default MainPage;
