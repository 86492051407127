import React from 'react';
import LetsTalk from './LetsTalk';
import Blog from "./BlogPreview";
import style from '../css/blogPost.module.scss';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";


function Post() {
	return (
		<div>
		<div className="application">
		<Helmet>
				<meta charSet="utf-8" />
				<title>Offshore Bank Accounts</title>
				<meta name="description" content="Opening offshore bank accounts and helping offshore companies to get a bank account. Also assisting with legal structuring and second citizenships." />
				<link rel="canonical" href="https://offshorebankaccounts.com" />
				<meta property="og:locale" content="en_US" />
				<meta property="og:type" content="website" />
				<meta property="og:title" content="Offshore Bank Accounts" />
				<meta property="og:description" content="Opening offshore bank accounts for businesses and individuals" />
				<meta property="og:url" content="https://offshorebankaccounts.com" />
				<meta property="og:site_name" content="Offshore Bank Accounts" />
				<meta property="og:image" content="https://offshorebankaccounts.com/assets/offshore-bank-og.png" />
				<meta property="og:image:secure_url" content="https://offshorebankaccounts.com/assets/offshore-bank-og.png" />
				<meta property="og:image:width" content="1280" />
				<meta property="og:image:height" content="720" />
		</Helmet>
		</div>
			<div className={style.headerImagePostThree}></div>
			<div className={style.container}>
				<div className={style.headerContainer}>
					<div className={style.header1}>
						<h1 className={style.header}>Five reasons you get turned-down by the banks</h1>
						<p className={style.author}>Gediminas Butkus / 2020 03 05</p>
					</div>
					<div className={style.header2}>
					{/*<button className={style.shareButton}>Share This Post</button>*/}
					<Link to='/blog'>
					<button className={style.backButton}><p>Back to Blog</p></button>
					</Link>
					</div>
				</div>
				<div>
					<div className={style.text}>
	{/*					<h2 className={style.littleHeader}>

						</h2>*/}
						<p className={style.paragraph}>
						I remember the times when you could open a bank account in an offshore jurisdiction and keep your assets sheltered from tax officers, creditors, or other governmental institutions. During those days, nobody would care who you were or where the money's coming from. This was the heyday of the offshore industry; people's money would move here and there yet nobody anticipated that one day everything could be completely traceable.
						</p>
						<p className={style.paragraph}>
						Those days are long gone now. Government's all around the globe took the issue seriously and so instituted numerous overarching regulations to manage the way banks trace and manage their customer activity. Effectively, this gave the government all the necessary tools to trace the money of their residents.
						</p>
						<p className={style.paragraph}>
						Nowadays, you wouldn't be able to find a single bank that would allow you to open an account without letting them know who is the ultimate beneficiary owner (UBO) of the account. Basically, you will have no other way but to disclose your identity to the bank, which effectively means the government as well; note that noncompliance implies that you're committing a crime.
						</p>
						<p className={style.paragraph}>
						However, let’s say you don’t have any problems revealing your identity to the government or a financial insinuation (smart choice, by the way); what other reasons would the banks have to refuse service to you?
						</p>
						<p className={style.paragraph}>
						<strong>Jurisdiction</strong> Everyone's heard about tax havens: the farther that the jurisdiction is, the better tax system it ensures. In most cases, your gains would not be taxed at all. While all that sounds intriguing, the situation today is different. Virtually no major bank will want to do any business with you because they see offshore entities as presenting a high risk. The fact of the matter is that most traditional banking institutions have a large number of low-risk or no-risk clients and see little to no gain from servicing an offshore institution. Your business can be completely legitimate, it could even hold real economic substance within the offshore jurisdiction, yet it is a risk that legacy banks are not willing to take.						</p>
						<p className={style.paragraph}>
						<strong>Citizenship</strong> of the UBO Most of the traditional banking institutions have a standing list of countries with whose citizens it won't do business. The real difference between major and small-scale institutions – appetite levels for risk. For instance, you might be refused services on the basis that you were born in Serbia. This also holds true to citizens of Iran, Russia, Uganda, or any other less developed country. This is where offshore banking comes in handy. Smaller financial institutions are willing to hear you out; however, some might still refuse services, while others might embrace you and your business.
						</p>
						<p className={style.paragraph}>
						<strong>Business model</strong> Your business model could also be a deciding factor in the eyes of financial institutions. Unfortunately, some models such as marketing, consulting, or software development are often used as cover by money launderers. Because of that, almost no major bank is willing to open an account for an offshore company that follows the aforementioned models. Yet smaller institutions are eager to give you a chance; given a number of alternative banking solutions out there it is a matter of finding the right choice that fits your needs.						</p>
						<p className={style.paragraph}>
						<strong>Monetary transactions</strong> Keep in mind that smaller financial institutions are under an enormous amount of scrutiny from regulatory bodies. Most of the alternative banking solutions follow strict anti-money laundering (AML) regulations, so be prepaid to provide a source of income, contractual documents that prove partnerships, proof of address and other similar records. Anyone running a business by the book should not encounter significant trouble while opening an account in an offshore institution.
						</p>
						<p className={style.paragraph}>
						<strong>Online presence</strong> Nowadays, we live in a very transparent and interconnected world where your presence on the Internet is just as significant as how you display yourself in person. I had many cases when clients got rejected by banks due to bad online presence alone. It is essential to take care of your image online as people or businesses with negative feedback on the Internet more often than others see the doors closed.
						</p>
						<p className={style.paragraph}>
						Trust me on this one: if you are going for an offshore banking solution, get some help from the professionals. Everyone will agree that having someone opening all the doors for you is easier than trying to get through on your own. Your goal is to be listened to.
						</p>
						<p className={style.paragraph}>
						As usual, some first-hand experience cases:
						</p>
						<h2 className={style.littleHeader}>Case #1 from the public</h2>
						<p className={style.paragraph}>
						<strong>Question:</strong> I'm from a Northern European EU-Country and I have been offered a freelance remote contract at $100k yearly for 20-hours per week as a Software Programmer. I am wondering if I should start invoicing through my own country's EU-company and pay maximum 50%+ tax first and later move to an offshore company or start immediately. It's not a real Incorporated/Limited company, so I'll have to pay maximum taxes on the invoices as income taxes and I cannot keep any money in the company. I'm trying to start off cheaply and not spend too much money, because it's not even guaranteed that the initial contract will go through. So I'm actually considering sending the first invoice through the EU company and EU bank account and switch later.
						</p>
						<p className={style.paragraph}>
						<strong>Our answer:</strong> Since you live in Asia, outside your "home" country, maybe you should consider changing your tax residency country from your "home" country to a more tax-favourable destination. It depends on actual circumstances, but it might be possible to achieve that neither country (i.e. nor your "home" country, nor Asian country you live in) would consider you a tax resident, so you could then become a tax resident in a third country. Then a lot of potential tax-effective structures might work. I don't know which country you specifically refer to, but most likely, I would not recommend going with a simple offshore company because of many potential issues (CFC risk, PE, banking issues). While more sophisticated solutions might be a bit expensive for the anticipated amount of income.
						</p>
						<h2 className={style.littleHeader}>Case #2 from the public</h2>
						<p className={style.paragraph}>
						<strong>Question:</strong> Do you have knowledge of some effective strategies to have full practical control without the controlling person being legally connected to a company? No great detail needed but if you could share your experience to outline one or more strategies, that would be very useful to me!
						</p>
						<p className={style.paragraph}>
						<strong>Our answer:</strong> I would say that first, it depends on the goal you wish to achieve and the general situation. But I would say it is not extremely easy. Especially if you wish to achieve real asset protection (even if creditors know that you have interest in certain assets they could not win a case against you and take those assets away), the same applies to tax benefits (even if tax authorities of your country know that you have interest in certain assets they could not tax you). You can look up more information about securitization, unit-linked life insurance, discretionary and irrevocable trusts/foundations. These are the main vehicles used to achieve these goals.
						</p>
					</div>
					{/*<div className={style.postImage}></div>
					<div className={style.text}>
						<p className={style.paragraph}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
							tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
							veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
							commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
							velit esse cillum dolore. Lorem ipsum dolor sit amet, consectetur
							adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
							magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
							laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
							in reprehenderit in voluptate velit esse cillum dolore.{' '}
						</p>
						<p className={style.paragraph}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
							tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
							veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
							commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
							velit esse cillum dolore. Lorem ipsum dolor sit amet, consectetur
							adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
							magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
							laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
							in reprehenderit in voluptate velit esse cillum dolore. Lorem ipsum
							dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
							incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
							quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
							consequat. Duis aute irure dolor in reprehenderit in voluptate velit
							esse cillum dolore. Lorem ipsum dolor sit amet, consectetur adipiscing
							elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
							Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
							ut aliquip ex ea commodo consequat. Duis aute irure dolor in
							reprehenderit in voluptate velit esse cillum dolore.
						</p>
					</div>*/}
				</div>
			</div>
			<h1 className={style.header}>Also you may like this</h1>
			<Blog />
			<LetsTalk />
		</div>
	);
}

export default Post;
