import letsTalk from "../css/letsTalk.module.scss";
import {ReactComponent as Envelope} from "../assets/mail.svg";
import {ReactComponent as Phone} from "../assets/phone.svg";
import React from "react";
import { Link } from 'react-router-dom';


function LetsTalk() {
	return (
		<div className={letsTalk.container}>
			<div>
				<h1 className={letsTalk.header}>Let’s Talk</h1>
				<div>
					<div className={letsTalk.contactEmail}>
						<Envelope />
						<div><a href="mailto:hello@offshorebankaccounts.com" >hello@offshorebankaccounts.com</a></div>
					</div>
					{/*<div className={letsTalk.contactNumber}>
						<Phone />
		<div><a href="18554350000" >1 855 435 0000</a></div>
					</div>*/}

				</div>
			</div>
			<div className={letsTalk.text}>
				<h2 className={letsTalk.littleHeader}>Tailor-made solutions for your needs</h2>
				<p className={letsTalk.paragraph}>
					We know that it is not easy to navigate in the offshore world, and we do not believe that there exists a one-fits-all solution.
				</p>
				<Link to="/contact">
				<button>Get in touch</button>
				</Link>
			</div>
		</div>
	);
}

export default LetsTalk;
