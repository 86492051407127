import React, { Component } from 'react';
import { ReactComponent as PaymentCards } from '../assets/initialcoinoffer.svg';
import { ReactComponent as BankAccounts } from '../assets/bankaccounts.svg';
import { ReactComponent as CompanyIncorporation } from '../assets/aboutus.svg';
import { ReactComponent as Citizenship } from '../assets/citizenship.svg';
import { ReactComponent as InitialCoinOfferings } from '../assets/initialcoinoffer.svg';
import { ReactComponent as ContactPicture } from '../assets/contact.svg';
import { ReactComponent as Envelope } from '../assets/mail.svg';
import { ReactComponent as Phone } from '../assets/phone.svg';
import { ReactComponent as Skype } from '../assets/skype.svg';
import { ReactComponent as Signal } from '../assets/signal.svg';
import { ReactComponent as Telegram } from '../assets/telegram.svg';
import style from '../css/contact.module.scss';
import {Helmet} from "react-helmet";

function Contact() {
	return (
		<div>
		<div className="application">
		<Helmet>
				<meta charSet="utf-8" />
				<title>Offshore Bank Accounts</title>
				<meta name="description" content="Opening offshore bank accounts and helping offshore companies to get a bank account. Also assisting with legal structuring and second citizenships." />
				<link rel="canonical" href="https://offshorebankaccounts.com" />
				<meta property="og:locale" content="en_US" />
				<meta property="og:type" content="website" />
				<meta property="og:title" content="Offshore Bank Accounts" />
				<meta property="og:description" content="Opening offshore bank accounts for businesses and individuals" />
				<meta property="og:url" content="https://offshorebankaccounts.com" />
				<meta property="og:site_name" content="Offshore Bank Accounts" />
				<meta property="og:image" content="https://offshorebankaccounts.com/assets/offshore-bank-og.png" />
				<meta property="og:image:secure_url" content="https://offshorebankaccounts.com/assets/offshore-bank-og.png" />
				<meta property="og:image:width" content="1280" />
				<meta property="og:image:height" content="720" />
		</Helmet>
		</div>
			<ContactUs />
			<LetsTalk />
			<StillDeciding />
		</div>
	);
}

function ContactUs() {
	return (
		<div className={style.contactUsContainer}>
			<div className={style.contactPicture}>
				<ContactPicture />
				<h1 className={style.header}>Contact Us</h1>
			</div>
			<div className={style.text}>
				<h2 className={style.littleHeader}>Money never sleeps, neither do we</h2>
				<p className={style.paragraph}>
					As serving clients from all around the globe and partnering with financial institutions in different times zones and being the intermediary between them our working hours never stops. So you are welcome to contact us via livechat on this website, email, telephone, Telegram, Signal or Skype. We will do our best to respond as quick as possible.
				</p>
			</div>
		</div>
	);
}

class LetsTalk extends Component {
	submitToServer = () => {
		const form = document.getElementById('help');
		const to = form.email.value;
		const from = 'hello@offshorebankaccounts.com';
		const text = `${form.helpNeeded.value} \n\n${form.name.value} \n\n${
			form.phoneNumber.value
		}`;
		const subject = 'Enquiry from website';
		fetch(`http://localhost:8000/sendemail`, {
			method: 'post',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				to,
				text,
				from,
				subject
			})
		}).then(response => console.log(response));
	};

	render() {
		return (
			<div className={style.letsTalkContainer}>
				<div>
					<h1 className={style.header}>Let’s Talk</h1>
					<div>
						<div className={style.contactEmail}>
							<Envelope />
							<p className={style.email}>hello@offshorebankaccounts.com</p>
						</div>
						<div className={style.contactNumber}>
							<Phone />
							<p className={style.number}>+34622862552</p>
						</div>
						<div className={style.contactEmail}>
							<Skype />
							<p className={style.email}><a href="skype:live:gediminas_71?chat">live:gediminas_71</a></p>
						</div>
						<div className={style.contactNumber}>
							<Telegram />
							<p className={style.number}>+34622862552</p>
						</div>
						<div className={style.contactNumber}>
							<Signal />
							<p className={style.number}>+34622862552</p>
						</div>
					</div>
				</div>
				<div className={style.second}>
				<div className={style.text}>
					<h2 className={style.littleHeader}>Pretty Good Protection (PGP)</h2>
					<p className={style.paragraph}>
					We care about your privacy, so we suggest to use Pretty Good Protection (PGP) encryption while communicating with us. You can find our public key below:
					</p>
					<form className={style.form} >
						<textarea name="helpNeeded" form="help" readonly>-----BEGIN PGP PUBLIC KEY BLOCK-----
mQENBF52fzIBCACbRg5bK4FWq4Miv51JCwh18vrTsu1yJDIr6ENGaClRO603uiGKPw6a0V+BdEXhnVLFbYJ0uhxBMniwBMOvOAABS7JhpzwwaIgKh3/p73R+PxCFpK/OhiVkeisgSaxigmyr0/ixGInBbpfJWYDBtN0/NwMXa94Xl6aV6Um5RgQ+NrN/9YqGCF4bsBOxjXFp6/n3Vpk9jPwdJgCU2JkheNObyRVIlw02qMWimDPj6LgpiU7UADBkbELuD6IhvoIEETR4zhjRHe9qCQFv16X3tFE7/bOJxBtm8U1I5SYrpj+UxPiEffd9ok7DOSQoK6LJ/tIjESFggy9edV0Z4L9zhFN9ABEBAAG0LkdlZGltaW5hcyBCdXRrdXMgPGdiQG9mZnNob3JlYmFua2FjY291bnRzLmNvbT6JAU4EEwEIADgWIQQRT7LrUbEn5bQy6jni4uiaW1d3bQUCXnZMgIbAwULCQgHAgYVCgkICwIEFgIDAQIeAQIXgAAKCRDi4uiaW1d3bYbXB/9no/5wPzO28ds34VvgeleRHTuz8nzDH3oQ+DmLI5zwuq9lMC4N0SFih+WJQE/sqExXCAsKGMk0kK/ak0uxyee/Uj05KbfZJsGL1a1bdivOW32N6bjIxp3zVKvqhWPNtFcAQeIBjfaX/VPDvz06FqHFCwXfOzam5+ymxe/xEqy5p1NvIfLzIF+rpaKAolts+j5ADHbId/YPqJhGUwDZKtVTqEagkMekkQ+MxIAszpkw0Wr55FHiN6liAEDSC7m/ylyE7Kql1omAf0u1Y+vZQV+mT0lfe2DqJvrFjkoIs1X5fcPRz0TPZXo9gwpTahK6URP332zIIdIUXRClMrFyxkPBuQENBF52fzIBCACxUIxFOzlWZ0IbmoOiBQAXzBi4WE/2eW1qWTa9qJFGuF11/8GTClOv3yLa69QtFM0uMO0EA9/iG0Q+f3NlaHn3wb6huBA7jAE1TYwRdd3tZ/oObD92JJHhGjfAlVV2rOkb4ExTGpTpmrp9EWmGlB+blFvlG8S6VboOJhB3nj28E3tw44niJwddxJHcLrxoUubpmW9IoiRcxYJNcQ2CD1jZ7s8v6QJNsOuL3MBITRAZATJq2Ik4Oyt3e32aEwfCXkJ6evZIy7bEmbcXx3HE5dbHKHu8HWaN8tWSo6sorc80yNttTfnUN0blecXNjedHNWDSCW8pA+rEYK/5IH2xjcXVABEBAAGJATYEGAEIACAWIQQRT7LrUbEn5bQy6jni4uiaW1d3bQUCXnZ/MgIbDAAKCRDi4uiaW1d3bfvEB/0RlnDXt9Dj0ywBIrU8oMzpqGEjY77+QK24WrOBpgslo9fLuuvDgShqG6qwMeIUTTMZUzNlYI/scAGUFstDDBuoJAqK5rr5c2rAY/imfRTdthMObUQJEa171f2j2MkR9+okG7qa2SSHJT4zcXlhLVLNWQf1/aCDZddRsTKBUv/Ll4UXiT2EITDqPNMjjABgZQXkhP8p8pY81wr6XTxhH3ARoNpZPwWoVt0FH4XQVDNHhDBzE/3euWrzgbZ1uAWs9aIb0znBgTddn3dklY+6DV9qhoBatqwPbp70hqm575B3Mf07nEEW4h6pGBb13scmPuSNrE1awm93TXSJRtgMkgur=ayX/



-----END PGP PUBLIC KEY BLOCK-----</textarea>
			</form>
			</div>
				</div>
				{/*<div className={style.second}>
					<h2 className={style.littleHeader}>Schedule Your Complimentary Consulation</h2>
					<form id="help" className={style.form} onSubmit={this.submitToServer}>
						<input type="text" name="name" placeholder="Your Name" required />
						<br />
						<input type="email" name="email" placeholder="Your Email" required />
						<br />
						<input
							type="text"
							name="phoneNumber"
							placeholder="Your Phone Number"
							required
						/>
						<br />
						<textarea name="helpNeeded" form="help" placeholder="How Can We Help?" />
						<br />
						<button>Submit</button>
					</form>
				</div>*/}
			</div>
		);
	}
}

function StillDeciding() {
	return (
		<div className={style.decidingContainer}>
			<h1 className={style.header}>Still Deciding?</h1>
			<div className={style.text}>
				<h2 className={style.littleHeader}>Talk with us about your case</h2>
				<p className={style.paragraph}>
					Maybe there is a solution for a problem you have or an opportunity for cost saving in your business setup you have never thought about. Contact us for a free consultation and we will see if we can cooperate. Our offerings include, but are not limited to:
				</p>
				<div className={style.servicesContainer}>
					<div>
						<BankAccounts />
						<p>Bank Account Opening</p>
					</div>
					<div>
						<PaymentCards />
						<p>Payment Acquiring</p>
					</div>
					<div>
						<CompanyIncorporation />
						<p>Company Formation</p>
					</div>
					<div>
						<Citizenship />
						<p>Secondary Citizenship</p>
					</div>
					<div>
						<InitialCoinOfferings />
						<p>Cryptocurrency Exchange</p>
					</div>
				</div>
			</div>
		</div>
	);
}
export default Contact;
