import React from 'react';

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import SocialMediaLinks from './SocialMediaLinks';
import style from '../css/footer.module.scss';
import {ReactComponent as ObcLogo} from "../assets/obclogo.svg";

function Footer() {
	const pStyle1 = {
  margin: '50px'
};
	return (
		<div className={style.container}>
			<Logo />
		{/*<p style={pStyle1}>Copyright &#9400; MSBH Re-Solution Holdings Limited, Cyprus </p>*/}
			<Menu />
		<div className={style.socialMedia}><SocialMediaLinks /></div>
		</div>
	);
}

function Logo() {
	return (
		<div className={style.logo}>
			<Link to="/">
				<ObcLogo />
			</Link>
		</div>
	);
}

function Menu() {
	return (
		<div className={style.buttons}>
			<Link to="/privacy">
				<p className={style.button} onClick={window.scrollTo(0, 0)}>
					<FormattedMessage id="buttonPrivacyPolicy" />
				</p>
			</Link>
			<Link to="/cookies">
				<p className={style.button} onClick={window.scrollTo(0, 0)}>
					<FormattedMessage id="buttonCookies" />
				</p>
			</Link>
			{/*<Link to="/services">
				<p className={style.button} onClick={window.scrollTo(0, 0)}>
					<FormattedMessage id="buttonServices" />
				</p>
			</Link>
			<Link to="/aboutUs">
				<p className={style.button} onClick={window.scrollTo(0, 0)}>
					<FormattedMessage id="buttonAboutUs" />
				</p>
			</Link>
			<Link to="/faq">
				<p className={style.button} onClick={window.scrollTo(0, 0)}>
					<FormattedMessage id="buttonFAQ" />
				</p>
			</Link>*/}
			<Link to="/blog">
				<p className={style.button} onClick={window.scrollTo(0, 0)}>
					<FormattedMessage id="buttonBlog" />
				</p>
			</Link>
			<Link to="/contact">
				<p className={style.button}>
					<FormattedMessage id="buttonContact" />
				</p>
			</Link>
		</div>
	);
}

export default Footer;
