import React from 'react';
import LetsTalk from './LetsTalk';
import style from '../css/termsAndConditions.module.scss';
import {Helmet} from "react-helmet";

function Privacy() {
	return (
		<div>
			<Policy />
			<LetsTalk />
		</div>
	);
}

function Policy() {
	return (
		<div className={style.container}>
		<div className="application">
		<Helmet>
				<meta charSet="utf-8" />
				<title>Offshore Bank Accounts</title>
				<meta name="description" content="Opening offshore bank accounts and helping offshore companies to get a bank account. Also assisting with legal structuring and second citizenships." />
				<link rel="canonical" href="https://offshorebankaccounts.com" />
				<meta property="og:locale" content="en_US" />
				<meta property="og:type" content="website" />
				<meta property="og:title" content="Offshore Bank Accounts" />
				<meta property="og:description" content="Opening offshore bank accounts for businesses and individuals" />
				<meta property="og:url" content="https://offshorebankaccounts.com" />
				<meta property="og:site_name" content="Offshore Bank Accounts" />
				<meta property="og:image" content="https://offshorebankaccounts.com/media/offshore-bank-og.png" />
				<meta property="og:image:secure_url" content="https://offshorebankaccounts.com/media/offshore-bank-og.png" />
				<meta property="og:image:width" content="1280" />
				<meta property="og:image:height" content="720" />
		</Helmet>
		</div>
			<h1 className={style.header}>Privacy Policy</h1>
			<div className={style.text}>
				<div>
					<h2 className={style.littleHeader}>1. Overview</h2>
					<p className={style.paragraph}>
					This Privacy Policy (the <strong>“Privacy Policy”</strong>) provides explanations on your personal data processing when you use our website (<a href="https://offshorebankaccounts.com/">https://offshorebankaccounts.com/</a>) (the <strong>“Website”</strong>) and the services (the <strong>“Services”</strong>) introduced on the Website.
						</p>
						<p className={style.paragraph}>

		Any personal data the Company collects, uses or shares about you is processed in relation with the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation) (<strong>“GDPR“</strong>) and other applicable laws.
		</p>
		<p className={style.paragraph}>
		MSBH RE-SOLUTION HOLDINGS LIMITED, company code HE386174, registered address at Stratigou Makrigianni 2, 3022 Limassol, Cyprus (the <strong>“Company“</strong> or <strong>“we“</strong>) is the data controller of your personal data processed for the purposes indicated in this Privacy Notice.
		</p>
		<p className={style.paragraph}>
		By contacting us via “Contact us” option or by filling the “Contact form” online or by using this Website and the Services other how you confirm you have read, understood and accepted this Privacy Policy.
					</p>
				</div>
				<div>
					<h2 className={style.littleHeader}>2. Personal data we collect</h2>
					<p className={style.paragraph}>
					Personal data is any information that relates to an identified or identifiable living individual. Different pieces of information, which collected together can lead to the identification of a particular person, also constitute personal data.
					</p>
					<p className={style.paragraph}>
					The personal data we may process about you depends on your use of the Website and the type of Services you are willing to get from us. Therefore, the processed personal data categories may include the following:
					</p>
					<p className={style.paragraph}>
					<strong>A.</strong>	Personal information (including, but not limited to, first name, last name, identity document data and its copy, country of residence for tax purpose, taxpayer identification number (TIN), address, phone number, e-mail, picture, video, data about you which may be provided in double-checking systems);
					</p>
					<p className={style.paragraph}>
					<strong>B.</strong>	Information about your occupation and income sources (including, but not limited to, type of occupation, countries related to your occupation);
					</p>
					<p className={style.paragraph}>
					<strong>C.</strong>	Financial information (including, but not limited to, source of your funds, monthly income, accounts in financial institutions);
					</p>
					<p className={style.paragraph}>
					<strong>D.</strong>	Information about your desirable Services (including, but not limited to purpose of the Services, your personal needs);
					</p>
					<p className={style.paragraph}>
					<strong>E.</strong>	Relationship data (including, but not limited to, information about relation to Politically Exposed Person (“PEP”));
					</p>
					<p className={style.paragraph}>
					<strong>F.</strong>	Communication data (including, but not limited to, correspondence, chats, date, time);
					</p>
					<p className={style.paragraph}>
					<strong>G.</strong>	History data (including, but not limited to, your experience using the Website, such as the register of timestamped actions performed by you on the Website);
					</p>
					<p className={style.paragraph}>
					<strong>H.</strong>	Information related to your use of the Website (including, but not limited to,  browser and device data (IP address(es), time zone, time, data, browser information, screen resolution electronic device‘s operational system information, location data (country (code), city), internet service provider (ISP), language version, etc.) and usage data (time spent on the Website, pages visited, links clicked, etc.)).

					</p>
				</div>
				<div>
					<h2 className={style.littleHeader}>3. Ways how we get your personal data</h2>
					<p className={style.paragraph}>
					The Company collects your personal data directly from you or from the third parties when:
					</p>
					<p className={style.paragraph}>
					<strong>A.</strong>	you contact us;
					</p>
					<p className={style.paragraph}>
					<strong>B.</strong>	you use our Website.
					</p>
				</div>
				<div>
					<h2 className={style.littleHeader}>4. Purposes for your personal data processing </h2>
					<p className={style.paragraph}>
					<strong>A.</strong>	Support services. For this purpose we process your personal data so that we could provide you the necessary consultation based on your expressed request and / or answer any inquiry you may have;
					</p>
					<p className={style.paragraph}>
					<strong>B.</strong>	Provision of the requested Services. For this purpose we evaluate your personal data so that we could suggest the most suitable type of Services for you and provide the agreed Services in the most efficient and effective manner;
					</p>
					<p className={style.paragraph}>
					<strong>C.</strong>	Proper and secure operation of the Website. For this purpose your personal data is processed in order to ensure smooth and secure operation of the Website.
					</p>
					<p className={style.paragraph}>
					If you do not provide the Company with the personal data necessary in order to achieve the above indicated purposes, the Company may not be able to provide you with the requested support or other Services, as well as, ensure proper and secure operation of the Websites. Therefore, the Company may not be able to keep a contact with you, continue business relationship, provide Services or perform other related functions.

					</p>
				</div>
				<div>
					<h2 className={style.littleHeader}>5. Legal basis for personal data processing </h2>
					<p className={style.paragraph}>
					We process your personal data based on at least one of the following grounds:
					</p>
					<p className={style.paragraph}>
					<strong>A.</strong>	you have given a consent (explicitly (i.e., confirming that you “Agree”) or implicitly (i.e., when you choose to contact us via “Contact us” option or by filling a “Contact form”, such initiative and action from your side is treated as your consent for processing your personal data by the Company for the purpose of provision support services to you));
					</p>
					<p className={style.paragraph}>
					<strong>B.</strong>	your personal data processing is necessary for the performance of a contract or in order to take steps at the request of you prior to entering into a contract;
					</p>
					<p className={style.paragraph}>
					<strong>C.</strong>	your personal data processing is necessary for compliance with a legal obligation to which the controller is a subject;
					</p>
					<p className={style.paragraph}>
					<strong>D.</strong>	your personal data is necessary for the purpose of the legitimate interests pursued by us or the third party.
					</p>
				</div>
				<div>
					<h2 className={style.littleHeader}>6. Personal data recipients</h2>
					<p className={style.paragraph}>
					We may disclose your personal data under initiative of our own or upon respective request to the below indicated personal data recipients which are also obliged to comply with the requirements of GDPR, laws and other mandatory legal requirements:
					</p>
					<p className={style.paragraph}>
					<strong>A.</strong>	banks, financial, payment and (or) electronic money institutions;
					</p>
					<p className={style.paragraph}>
					<strong>B.</strong>	auditors, legal and financial consultants;
					</p>
					<p className={style.paragraph}>
					<strong>C.</strong>	public authorities;
					</p>
					<p className={style.paragraph}>
					<strong>D.</strong>	information technology infrastructure services providers;
					</p>
					<p className={style.paragraph}>
					<strong>E.</strong>	fraud detection services providers;
					</p>
					<p className={style.paragraph}>
					<strong>F.</strong>	data processors;
					</p>
					<p className={style.paragraph}>
					<strong>G.</strong>	other service providers which services may include, or which are engaged in personal data processing executed by the Company.

					</p>
				</div>
				<div>
					<h2 className={style.littleHeader}>7. Data transfers</h2>
					<p className={style.paragraph}>
					Generally, the Company process your personal data within the European Union ("EU") or the European Economic Area ("EEA"), however, there might be some cases when the Company cooperates with the recipients outside EU or EEA. In such cases the Company makes all reasonable efforts to ensure that at least one of the following GDPR requirements is complied:
					</p>
					<p className={style.paragraph}>
					<strong>A.</strong>	the recipient is located in the territory which is acknowledged by the European Commission as ensuring the adequate level of personal data protection;
					</p>
					<p className={style.paragraph}>
					<strong>B.</strong>	the recipient is in the United States of America and has been certified under Privacy Shield Framework;
					</p>
					<p className={style.paragraph}>
					<strong>C.</strong>	the Company and the recipient have concluded the agreement which includes standard contractual clauses (SCC) which were approved by the European Commission as the clauses that offer sufficient safeguards on data protection for the personal data to be transferred internationally;
					</p>
					<p className={style.paragraph}>
					<strong>D.</strong>	the codes of conduct or other security measures under GDPR has been complied.

					</p>
				</div>
				<div>
					<h2 className={style.littleHeader}>8. Personal data retention periods</h2>
					<p className={style.paragraph}>
					We process your personal data for no longer than it is necessary in order to achieve the purposes for which they were collected (Article 4).
					</p>
					<p className={style.paragraph}>
					Business practice, legal obligations applied to the Company and maintenance of Company’s ability to establish, exercise and defend possible legal claims may also be the indicators for determination of data retention periods.
					</p>
					<p className={style.paragraph}>
					Depending on the category of personal data and the purpose it is being processed for, your data retention periods applied within the Company are:
					</p>
					<p className={style.paragraph}>
					<strong>A.</strong>	in order to provide support services to you we may process your personal data throughout the provision of support services and for 2 (two) years after our last actual contact with each other (i.e., our last e-mail to you answering your request). If during our communication you request specific Services and we agree to start working on that, then your personal data collected for the purpose of providing support services will be further processed for the purpose of provision of the requested Services and for the period indicated in Paragraph B below;
					</p>
					<p className={style.paragraph}>
					<strong>B.</strong>	when you request specific Services and we agree to start working on that we process the personal data that you have provided throughout our continues business relationship and for 5 (five) years after our business relationship ends (i.e., the requested Services are deemed to be provided);
					</p>
					<p className={style.paragraph}>
					<strong>C.</strong>	for the purpose of proper and secure operation of the Website, we process your personal data throughout the term we support the Website.
					</p>
				</div>
				<div>
					<h2 className={style.littleHeader}>9. Security measures</h2>
					<p className={style.paragraph}>
					The Company takes various security ensuring technologies and procedures in order to protect your personal data against unauthorised or unlawful processing, accidental loss, misuse, unauthorized access, illegal usage, destruction, disclosure, damage and etc. This includes legal, organisational, technical, and physical security measures, such as the latest security systems, working only with trustworthy service providers, ability to detect cyber security attacks and other threats to the integrity of the Website. However, no transmission of information via email or other telecommunication channels through the internet could be fully secured. Therefore, you should take due care when you are sharing confidential information via e-mail or other telecommunication channels.
					</p>
				</div>
				<div>
					<h2 className={style.littleHeader}>10. Cookies</h2>
					<p className={style.paragraph}>
Cookies are small information files that your device (personal computer, telephone, etc.) browser finds on the Website and stores in your device. In order to get to know more about cookies, please read our Cookie Policy.
				</p>
				</div>
				<div>
					<h2 className={style.littleHeader}>11. Your data protection rights</h2>
					<p className={style.paragraph}>
					You have certain legal rights in relation to the processing of your personal data:
					</p>
					<p className={style.paragraph}>
				 	<strong>A.</strong>	Right of access to the information that is processed about you;
				 </p>
				 <p className={style.paragraph}>
				 	<strong>B.</strong>	Rights to rectification;
				 </p>
				 <p className={style.paragraph}>
				 	<strong>C.</strong>	Rights to erasure;
				 </p>
				 <p className={style.paragraph}>
				 	<strong>D.</strong>	Right to restriction of processing;
				 </p>
				 <p className={style.paragraph}>
				 	<strong>E.</strong>	Right to data portability;
				 </p>
				 <p className={style.paragraph}>
				 	<strong>F.</strong>	Right to object.
				 </p>
				 <p className={style.paragraph}>
				 This Privacy Notice does not deprive you of any other legal rights you may enforce under the applicable law.
				 </p>
				 <p className={style.paragraph}>
				 You may be able to exercise your rights only after the Company has successfully identified you. If the Company is not sure about the identity of the person sending the request, the Company may not provide the requested information to him / her, unless his / her identity is confirmed. Therefore, if you are sending a request via e-mail, kindly ask you to attach a certified copy of your ID document. In addition, the Company keeps the right to decide the other or additional legitimate means of identification proof that should be requested, such as a selfie with your ID document, video or voice call, or any other additional document which could let to determine your identity.
				 </p>
				 <p className={style.paragraph}>
				 You may be provided with information related to the exercise of your rights free of charge. However, your request for the exercise of rights may be waived or may be subject to an appropriate fee if the request is manifestly unfounded or excessive, in particular because of their repetitive character.
				 </p>
				 <p className={style.paragraph}>
				 The Company shall provide you with information on the actions taken upon receipt of your request for the exercise of your rights or the reasons for the inaction no later than within 1 month from the receipt of the request. The period for submitting the requested information may be extended, if necessary, for 2 more months, depending on the complexity and number of requests. If you have submitted the request by electronic means, the information shall also be provided by electronic means.
				 </p>
				 <p className={style.paragraph}>
				 If you consider that your personal data is being processed in violation of your rights and legitimate interests in accordance with applicable law, you shall have the right to file a complaint against the processing of personal data to the State Data Protection Inspectorate located in Cyprus.

				</p>
				</div>
				<div>
					<h2 className={style.littleHeader}>12. Final provisions</h2>
					<p className={style.paragraph}>
					If you have any questions regarding this Privacy Notice or if you want to execute your rights you may contact us via e-mail: hello@offshorebankaccounts.com.
					</p>
					<p className={style.paragraph}>
					This Privacy Notice shall be viewed and applied in accordance with GDPR and other applicable laws.
					</p>
					<p className={style.paragraph}>
					The Company reserves the right to revise this Privacy Notice from time to time in order to correspond with the changes of its business practice or applied legal requirements. The revised Privacy Notice will be effective upon the posting of it on the Website and you have a sole responsibility to review it. Your continued use of the Website and Services following any such revisions to the Privacy Notice will constitute your acceptance of such changes.
				</p>
				</div>
			</div>
		</div>
	);
}
export default Privacy;
