import React from 'react';
import LetsTalk from './LetsTalk';
import Blog from "./BlogPreview";
import style from '../css/blogPost.module.scss';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";


function Post() {
	return (
		<div>
		<div className="application">
		<Helmet>
				<meta charSet="utf-8" />
				<title>Why I decided to start? | Offshore Bank Accounts Blog</title>
				<meta name="description" content="A story about how I ended up navigating people and businesses in the offshore world" />

		</Helmet>
		</div>
			<div className={style.headerImage}></div>
			<div className={style.container}>
				<div className={style.headerContainer}>
					<div className={style.header1}>
						<h1 className={style.header}>Why I decided to start this business?</h1>
						<p className={style.author}>Gediminas Butkus / 2020 02 18</p>
					</div>
					<div className={style.header2}>
					{/*<button className={style.shareButton}>Share This Post</button>*/}
					<Link to='/blog'>
					<button className={style.backButton}><p>Back to Blog</p></button>
					</Link>
					</div>
				</div>
				<div>
					<div className={style.text}>
	{/*					<h2 className={style.littleHeader}>

						</h2>*/}
						<p className={style.paragraph}>
Before I go into the whole story of why I chose to pursue what I do, I want to explain a couple of things about myself. First and foremost, I am a citizen of the world, a passionate entrepreneur, a loving husband, and a dedicated father. I care about my children’s future, and I’m constantly educating them about freedom, democracy, and wisdom.
						</p>
						<p className={style.paragraph}>
						When I say freedom, I mean it in every sense of the word — freedom to travel, freedom of speech, freedom to decide, you name it. It’s indeed, what I believe is the core element for achieving fulfilment and happiness. However, what I would like to focus on in this blog post, and one of the things I know best is the freedom to make your own decisions when it comes to your finances.
						</p>
						<p className={style.paragraph}>
						Nowadays, governments are putting a lot of effort to get their citizens controlled as much as possible. They are trying to keep an eye on everyone and everything, they want to dictate what is right or wrong, and they are even trying to tell you what you can and can’t do with your own money.
						</p>
						<p className={style.paragraph}>
						The times when people could easily and freely open bank accounts for their international or e-commerce businesses are over. These days all financial institutions are regulated with more and more stringent rules being pushed onto the industry. Because of that, banks have started to deny the majority of the requests they get. The main reason — they fear being fined massive amounts of money (history has shown us many examples of this kind already). Hence, they won’t even listen to your legitimate business story, especially if your business is registered, let’s say, in one of the more exotic jurisdictions like Seychelles, Mauritius or Bahamas.
						</p>
						<p className={style.paragraph}>
						Despite the grim picture I’ve just painted, I am here to tell you that you can be your own decision-maker. You can decide where you want to pay taxes, where and how to spend your money and where to park it for your future generations, which brings me to the key point that I want to cover in this blog post — how I can help you take back the freedom over your finance.
						</p>
						<h2 className={style.littleHeader}>How I can help your business</h2>
						<p className={style.paragraph}>
						I’m a guy, who has been swimming in the banking oceans like a shark, and opening doors even for companies that least expect the positive results. I have a vast network of financial institutions around the globe, and most importantly, I can make them listen to your story.
						</p>
						<p className={style.paragraph}>
						I work together with a partner who is a true guru in international taxation. In my opinion, he’s actually one of the best in this field. Both of us can provide expert advice and help take care of tax optimization and international structures formation. I can also personally lead you in the process of opening accounts in the financial institutions, both offshore and onshore.
						</p>
						<p className={style.paragraph}>
						I have started this blog primarily for educational purposes. My goal is to demonstrate that the obstacles you face in your business due to a hostile regulatory environment are not the dead-end. I’ll be sharing advice on how you can solve them by using impersonal cases of my clients or simply problems people have publically shared on the Internet as examples.
						</p>
						<p className={style.paragraph}>
						I want to stress that I strongly recommend to always get a personal consultation with a professional first before you start any legal activities. Every situation is unique with its own circumstances, and as you may already know, I don’t believe there exists a one-fits-all solution.
						</p>
						<h2 className={style.littleHeader}>A case from the public</h2>
						<p className={style.paragraph}>
						<strong>Question:</strong> I have a company in the EU that is currently owned by me personally and I’m looking for the most cost-effective way to protect my ownership from creditors, lawsuits, etc. The country in question has civil law. Based on the data I’ve seen, most people in my country use a Panama foundation or a Liechtenstein trust (although I’ve seen a foundation being used as well). I’ve also heard that a Cook Islands trust is absolutely the best and most secure asset protection solution in existence.
						</p>
						<p className={style.paragraph}>
						<strong>Our answer:</strong> Jurisdiction is important; however, what’s even more important is how the asset holding/protection vehicle is structured. In any case, asset protection (from potential creditors) works only if you don’t have any claim in them at the moment you contribute the assets to the structure.  Otherwise, in most countries, such transactions would be considered as artificial from a civil law perspective. Properly structured irrevocable discretionary foundation in Liechtenstein might work as a good asset protection vehicle, but it depends on many factors. Other options to consider are unit-linked life insurance solutions and securitization based solutions.
						Do you have any questions regarding your situation? I would be more than happy to help figure out the best scenario for you!

						</p>
					</div>
					{/*<div className={style.postImage}></div>
					<div className={style.text}>
						<p className={style.paragraph}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
							tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
							veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
							commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
							velit esse cillum dolore. Lorem ipsum dolor sit amet, consectetur
							adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
							magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
							laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
							in reprehenderit in voluptate velit esse cillum dolore.{' '}
						</p>
						<p className={style.paragraph}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
							tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
							veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
							commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
							velit esse cillum dolore. Lorem ipsum dolor sit amet, consectetur
							adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
							magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
							laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
							in reprehenderit in voluptate velit esse cillum dolore. Lorem ipsum
							dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
							incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
							quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
							consequat. Duis aute irure dolor in reprehenderit in voluptate velit
							esse cillum dolore. Lorem ipsum dolor sit amet, consectetur adipiscing
							elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
							Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
							ut aliquip ex ea commodo consequat. Duis aute irure dolor in
							reprehenderit in voluptate velit esse cillum dolore.
						</p>
					</div>*/}
				</div>
			</div>
			<h1 className={style.header}>Also you may like this</h1>
			<Blog />
			<LetsTalk />
		</div>
	);
}

export default Post;
