import React from 'react';
import {Link} from "react-router-dom";
import blog from '../css/blogPreview.module.scss';

function BlogPostPreview() {
	return (
		<div className={blog.hover}>
			<div className={blog.blogPostContainer}>
			<Link to="/blog/why-you-get-rejected-by-banks">
				 <img src={require('./../assets/Rejected-by-banks-small.jpg')} height='100%' width='100%' align="middle"/>
				{/*<p className={blog.paragraph}>Image</p>*/}
				<h2 className={blog.littleHeader}>Why you get rejected by banks?</h2>
				<p className={blog.paragraph}>
					Banks compliance department is like a black box, but we present most common reasons why banks decline to open an account.
				</p></Link>
			</div>
		</div>
	);
}
export default BlogPostPreview;
