import React from 'react';
import LetsTalk from './LetsTalk';
import style from '../css/termsAndConditions.module.scss';
import {Helmet} from "react-helmet";

function Privacy() {
	return (
		<div>
			<Policy />
			<LetsTalk />
		</div>
	);
}

function Policy() {
	return (
		<div className={style.container}>
		<div className="application">
		<Helmet>
				<meta charSet="utf-8" />
				<title>Offshore Bank Accounts</title>
				<meta name="description" content="Opening offshore bank accounts and helping offshore companies to get a bank account. Also assisting with legal structuring and second citizenships." />
				<link rel="canonical" href="https://offshorebankaccounts.com" />
				<meta property="og:locale" content="en_US" />
				<meta property="og:type" content="website" />
				<meta property="og:title" content="Offshore Bank Accounts" />
				<meta property="og:description" content="Opening offshore bank accounts for businesses and individuals" />
				<meta property="og:url" content="https://offshorebankaccounts.com" />
				<meta property="og:site_name" content="Offshore Bank Accounts" />
				<meta property="og:image" content="https://offshorebankaccounts.com/assets/offshore-bank-og.png" />
				<meta property="og:image:secure_url" content="https://offshorebankaccounts.com/assets/offshore-bank-og.png" />
				<meta property="og:image:width" content="1280" />
				<meta property="og:image:height" content="720" />
		</Helmet>
		</div>
			<h1 className={style.header}>Cookie Policy</h1>
			<div className={style.text}>
				<div>
					<h2 className={style.littleHeader}>1. What are cookies</h2>
					<p className={style.paragraph}>
					Cookies are small text files that are automatically placed on your computer, mobile phone or other device once you enter our website <a href="https://offshorebankaccounts.com/">https://offshorebankaccounts.com/</a> (hereinafter the <strong>"Website"</strong>). They are stored by your internet browser and may contain basic information about your device or internet use.
					</p>
					<p className={style.paragraph}>
						As explained further in the Cookie Notice (the <strong>"Cookie Notice"</strong>), cookies help us to enhance your experience when using the Website, i.e., to remember your browsing habits, actions and settings, and customise some other functions. There are also cookies which allow us to monitor the frequency of your visits and collect general statistical information about the Website's traffic, as well as deliver personalised content and track the effectiveness of our ad campaigns on third party websites (if applicable). Different classifications of cookies that are used on the Website may be available and some cookies may contain features from different cookie types.
						</p>
				</div>
				<div>
					<h2 className={style.littleHeader}>2. What type of cookies do we use in our Website? </h2>
					<p className={style.paragraph}>
					The main categories of cookies we use in our Website:
					</p>
					<p className={style.paragraph}>
					<strong>A. Necessary cookies</strong>
					</p>
					<p className={style.paragraph}>
					Necessary cookies are essential for the Website's navigation, performance and functionality. These cookies are required in order to deliver information and services to you. For example, necessary cookies are used to display content, allow you to navigate through the Website, etc. Without these cookies, some parts of our Website cannot function properly. Please note that necessary cookies are essential and do not require consent.
					</p>
					<table className={style.table}>
						<tr>
							<td>Name</td>
							<td>Source</td>
							<td>Purpose</td>
							<td>Creation time</td>
						</tr>
						<tr>
							<td>40</td>
							<td>Exercitation ullamco laboris</td>
							<td>Lipsum dolor</td>
							<td>Exercitation ullamco laboris</td>
						</tr>
					</table>
					<p className={style.paragraph}>
					<strong>B. Preference cookies</strong>
					</p>
					<p className={style.paragraph}>
					Preference cookies enable our Website to remember information that alters the way the Website looks or functions. For example, preference cookies could help to show you the content of the Website based on your current location.
					</p>
					<table className={style.table}>
						<tr>
							<td>Name</td>
							<td>Source</td>
							<td>Purpose</td>
							<td>Creation time</td>
						</tr>
						<tr>
							<td>40</td>
							<td>Exercitation ullamco laboris</td>
							<td>Lipsum dolor</td>
							<td>Exercitation ullamco laboris</td>
						</tr>
					</table>
					<p className={style.paragraph}>
					<strong>C. Analytics cookies</strong>
					</p>
					<p className={style.paragraph}>
					Analytics cookies help us to understand how you use and interact with the Website and allow us to make it better. These cookies collect information and report Website usage statistics without personally identifying individual Website’s visitor. For example, these cookies collect information about how visitors move around the Website when they are using it and help us to identify recurring errors that they may encounter. This allows us to provide necessary information for our users and to update the Website according to their interests.
					</p>
					<table className={style.table}>
						<tr>
							<td>Name</td>
							<td>Source</td>
							<td>Purpose</td>
							<td>Creation time</td>
						</tr>
						<tr>
							<td>40</td>
							<td>Exercitation ullamco laboris</td>
							<td>Lipsum dolor</td>
							<td>Exercitation ullamco laboris</td>
						</tr>
					</table>
				</div>
				<div>
					<h2 className={style.littleHeader}>3. Third party cookies</h2>
					<p className={style.paragraph}>
These cookies are set on our Website by someone other than the Website’s owner for the purposes such as collecting information on user behaviour, demographics, or targeted marketing, etc. By setting their cookies on our website third parties may also use other partners for cookie matching which may implement their cookies, as well. We do not have control over the placement of cookies by other websites, therefore, for more information about third party cookies and partners engaged by third-party you should look at third-party's website (i.e. third-party's privacy policy).					</p>

				</div>
				<div>
					<h2 className={style.littleHeader}>4.	How to manage your cookie preferences?</h2>
					<p className={style.paragraph}>
					You can manage your cookie preferences through the browser which you are using settings. Each browser is different, but at most cases you should be able to change the settings via selection of “Help”, “Tools” or “Edit” menus in your browser. Your device's operating system may also have additional cookie controls, so you can change them in order to refuse the use of cookies.
					</p>
					<p className={style.paragraph}>
					Please note that disabling a cookie or category of cookies does not delete the cookie from your browser unless manually completed through your browser function.
					</p>
					<p className={style.paragraph}>
					Also your device's operating system may have additional cookie controls, so you can change them in order to refuse the use of cookies.
					</p>
				</div>
				<div>
					<h2 className={style.littleHeader}>5. Miscellaneous</h2>
					<p className={style.paragraph}>
					The Website is administrated by the MSBH RE-SOLUTION HOLDINGS LIMITED, company code HE386174, registered address at Stratigou Makrigianni 2, 3022 Limassol, Cyprus.
					</p>
					<p className={style.paragraph}>
					If you have any questions regarding this Cookie Notice you can contact us via e-mail: <a href="mailto:hello@offshorebankaccounts.com">hello@offshorebankaccounts.com</a>.
					</p>
					<p className={style.paragraph}>
					Please be aware that we keep the right to update the current Cookie Notice from time to time in order to comply with the changes in legislation or according to our business needs or based on some other relevant reasons. All changes shall come into force upon posting the revised Cookie Notice on the Website and it is your own responsibility to get acknowledged with it. Your continued use of the Website following any revisions to the Cookie Notice will constitute your acceptance of such changes.
					</p>
				</div>
			</div>
		</div>
	);
}
export default Privacy;
