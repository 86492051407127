import React from 'react';
import LetsTalk from './LetsTalk';
import style from '../css/service.module.scss';
import { ReactComponent as SafeImage } from '../assets/bankaccounts.svg';
import { ReactComponent as PaymentCardsImage } from '../assets/initialcoinoffer.svg';
import {Link} from "react-router-dom";

function Services() {
	return (
		<div>
			<BankAccountOpening />
			<PartnerBanks />
			<PaymentCards />
			<PartnerBanksShort />
			<LetsTalk />
		</div>
	);
}

function BankAccountOpening() {
	return (
		<div className={style.mainContainer}>
			<div className={style.headerText}>
				<h1 className={style.header}>Bank Account Opening</h1>
				<SafeImage />
				<button>Order now</button>
			</div>
			<div className={style.text}>
				<p className={style.paragraph}>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
					incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
					nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
					Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.
				</p>
				<p className={style.paragraph}>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
					incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
					nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
					Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.
				</p>
			</div>
		</div>
	);
}
function PartnerBanks() {
	return (
		<div className={style.servicesContainer}>
			<div className={style.firstColumn}>
				<h2 className={style.littleHeader}>Our partner banks</h2>
				<ul className={style.countries}>
					<Link to="services/Anguilla">
						<li>Anguilla</li>
					</Link>
					<li> Belize</li>
					<li> British virgin Islands</li>
					<li> Dominica </li>
					<li>Duai</li> <li>Gibraltar</li>
					<li> Hong Kong</li>
					<li> Latvia</li>
					<li> Liechtenstein</li>
					<li> Marshall Islands</li> <li>Panama </li>
					<li>Scotland</li>
					<li> Seychelles</li>
					<li> Switzerland</li>
				</ul>
			</div>
			<div className={style.secondColumn}>
				<h2 className={style.littleHeader}>Dolor sit amet</h2>
				<p className={style.paragraph}>
					Lipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
					incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
					nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
					Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.
				</p>
				<table className={style.table}>
					<tr>
						<td>Lipsum</td>
						<td>Lipsum dolor</td>
						<td>Lipsum dolor</td>
						<td>Lipsum dolor</td>
					</tr>
					<tr>
						<td>40</td>
						<td>Exercitation ullamco laboris</td>
						<td>Lipsum dolor</td>
						<td>Exercitation ullamco laboris</td>
					</tr>
					<tr>
						<td>40</td>
						<td>Ullamco laboris nisi ut aliquip ex</td>
						<td>Lipsum dolor</td>
						<td>Ullamco laboris nisi ut aliquip ex</td>
					</tr>
					<tr>
						<td>40</td>
						<td>Exercitation ullamco laboris</td>
						<td>Lipsum dolor</td>
						<td>Exercitation ullamco laboris</td>
					</tr>
					<tr>
						<td>40</td>
						<td>Ullamco laboris nisi ut aliquip ex</td>
						<td>Lipsum dolor</td>
						<td>Ullamco laboris nisi ut aliquip ex</td>
					</tr>
				</table>
				<h2 className={style.littleHeader}>Dolor sit amet </h2>
				<ul className={style.countries}>
					<li>- Highest security standards</li> <li>- No credit score </li>
					<li>- Strict banking secrecy</li>{' '}
					<li>- Account currency optionally in EUR, USD, GBP, CHF or YEN </li>
					<li>- Direct bank transfer or via correspondent banks per IBAN and BIC </li>
					<li>- Asset management and diversified portfolio management</li>{' '}
					<li>- Direct Access to the worldwide SWIFT system</li>{' '}
					<li>- Access to online and phone banking</li> <li>- Debit and credit cards</li>
				</ul>
				<div className={style.warning}>
					{/* <img/> */}
					<p>Please also see the restrictions of this service.</p>
				</div>
			</div>
		</div>
	);
}
function PaymentCards() {
	return (
		<div className={style.mainContainer}>
			<div className={style.headerText}>
				<h1 className={style.header}>Payment Cards</h1>
				<PaymentCardsImage />
				<button>Order now</button>
			</div>
			<div className={style.text}>
				<p className={style.paragraph}>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
					incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
					nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
					Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.
				</p>
				<p className={style.paragraph}>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
					incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
					nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
					Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.
				</p>
			</div>
		</div>
	);
}
function PartnerBanksShort() {
	return (
		<div className={style.servicesContainer}>
			<div className={style.firstColumn}>
				<h2 className={style.littleHeader}>Our partner banks</h2>
				<ul className={style.countries}>
					<li>Anguilla</li>
					<li> Belize</li>
					<li> British virgin Islands</li>
					<li> Dominica </li>
					<li>Duai</li> <li>Gibraltar</li>
					<li> Hong Kong</li>
					<li> Latvia</li>
					<li> Liechtenstein</li>
					<li> Marshall Islands</li> <li>Panama </li>
					<li>Scotland</li>
					<li> Seychelles</li>
					<li> Switzerland</li>
				</ul>
			</div>
			<div className={style.secondColumn}>
				<h2 className={style.littleHeader}>Dolor sit amet </h2>
				<ul className={style.countries}>
					<li>- Highest security standards</li> <li>- No credit score </li>
					<li>- Strict banking secrecy</li>{' '}
					<li>- Account currency optionally in EUR, USD, GBP, CHF or YEN </li>
					<li>- Direct bank transfer or via correspondent banks per IBAN and BIC </li>
					<li>- Asset management and diversified portfolio management</li>{' '}
					<li>- Direct Access to the worldwide SWIFT system</li>{' '}
					<li>- Access to online and phone banking</li> <li>- Debit and credit cards</li>
				</ul>
				<div className={style.warning}>
					{/* <img/> */}
					<p>Please also see the restrictions of this service.</p>
				</div>
			</div>
		</div>
	);
}

export default Services;
