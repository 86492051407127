import React from 'react';
import { ReactComponent as Twitter } from '../assets/tw.svg';
import { ReactComponent as LinkedIn } from '../assets/link.svg';

import style from '../css/socialMedia.module.css';

function SocialMediaLinks() {
	return (
		<div className={style.container}>
			{/*<Twitter />*/}
			<a href='https://www.linkedin.com/company/offshorebankaccounts/' target='_blank'><LinkedIn /></a>
		</div>
	);
}
export default SocialMediaLinks;
