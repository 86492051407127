import React from 'react';
import LetsTalk from './LetsTalk';
import style from '../css/service.module.scss';
import { ReactComponent as SafeImage } from '../assets/bankaccounts.svg';
import { ReactComponent as PaymentCardsImage } from '../assets/initialcoinoffer.svg';
import { ReactComponent as Citizenship } from '../assets/citizenship.svg';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

function Services() {
	return (
		<div>
		<div className="application">
		<Helmet>
				<meta charSet="utf-8" />
				<title>Second Citizenship | Offshore Bank Accounts</title>
				<meta name="description" content="Second passport is an insurance for an international person. Citizenship by investment is available in Bulgaria, Cyprus, Dominica, Malta, St. Kitts, St. Lucia, Vanuatu and other countries." />
				<link rel="canonical" href="https://offshorebankaccounts.com" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Offshore Bank Accounts" />
        <meta property="og:description" content="Opening offshore bank accounts for businesses and individuals" />
        <meta property="og:url" content="https://offshorebankaccounts.com" />
        <meta property="og:site_name" content="Offshore Bank Accounts" />
        <meta property="og:image" content="https://offshorebankaccounts.com/assets/offshore-bank-og.png" />
        <meta property="og:image:secure_url" content="https://offshorebankaccounts.com/assets/offshore-bank-og.png" />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="720" />
		</Helmet>
		</div>
			<BankAccountOpening />
			<SupportedCompanies />
			<LetsTalk />
		</div>
	);
}

function BankAccountOpening() {
	return (
		<div className={style.mainContainer}>
			<div className={style.headerText}>
				<h1 className={style.header}>Second Citizenship</h1>
				<Citizenship />
				{/*<button>Order now</button>*/}
			</div>
			<div className={style.text}>
				<p className={style.paragraph}>
					In the global and uncertain world second citizenship is a valuable asset. It is popular because can provide visa-free travelling in most countries. Also second passport is an insurance policy for geopolitical risks.
				</p>
			</div>
		</div>
	);
}
function SupportedCompanies() {
	return (
		<div className={style.servicesContainer}>
			<div className={style.firstColumn}>
				<h2 className={style.littleHeader}>Available Citizenships</h2>
				<ul className={style.countries}>
					<li>Antigua and Barbuda</li>
					<li>Bulgaria</li>
					<li>Cyprus</li>
					<li>Dominica</li>
					<li>Grenada</li>
					<li>Malta</li>
					<li>St. Kitts</li>
					<li>St. Lucia</li>
					<li>Vanuatu</li>
				</ul>
			</div>
			<div className={style.secondColumn}>
				{/*<h2 className={style.littleHeader}>Dolor sit amet</h2>
				<p className={style.paragraph}>
					Lipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
					incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
					nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
					Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.
				</p>*/}
				{/*<table className={style.table}>
					<tr>
						<td>Lipsum</td>
						<td>Lipsum dolor</td>
						<td>Lipsum dolor</td>
						<td>Lipsum dolor</td>
					</tr>
					<tr>
						<td>40</td>
						<td>Exercitation ullamco laboris</td>
						<td>Lipsum dolor</td>
						<td>Exercitation ullamco laboris</td>
					</tr>
					<tr>
						<td>40</td>
						<td>Ullamco laboris nisi ut aliquip ex</td>
						<td>Lipsum dolor</td>
						<td>Ullamco laboris nisi ut aliquip ex</td>
					</tr>
					<tr>
						<td>40</td>
						<td>Exercitation ullamco laboris</td>
						<td>Lipsum dolor</td>
						<td>Exercitation ullamco laboris</td>
					</tr>
					<tr>
						<td>40</td>
						<td>Ullamco laboris nisi ut aliquip ex</td>
						<td>Lipsum dolor</td>
						<td>Ullamco laboris nisi ut aliquip ex</td>
					</tr>
				</table>*/}
				<h2 className={style.littleHeader}>Our services include</h2>
				<ul className={style.countries}>
					<li>- Analysis of your current situation and of all-important personal circumstances</li>
					<li>- Recommendation of suitable citizenship program</li>
					<li>- Introduction to the relevant authority</li>
					<li>- Assitance in preparation of application and required legal documents </li>
				</ul>
				{/*<div className={style.warning}>
					<img/>
					<p>Our prices start from 1500 EUR  for 10 hours of work including analysis of all-important personal circumstances, preparation of memo with structuring alternatives, answering all related questions, conference calls, etc.</p>
				</div>*/}
			</div>
		</div>
	);
}
function PaymentCards() {
	return (
		<div className={style.mainContainer}>
			<div className={style.headerText}>
				<h1 className={style.header}>Payment Cards</h1>
				<PaymentCardsImage />
				<button>Order now</button>
			</div>
			<div className={style.text}>
				<p className={style.paragraph}>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
					incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
					nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
					Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.
				</p>
				<p className={style.paragraph}>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
					incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
					nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
					Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.
				</p>
			</div>
		</div>
	);
}
function PartnerBanksShort() {
	return (
		<div className={style.servicesContainer}>
			<div className={style.firstColumn}>
				<h2 className={style.littleHeader}>Our partner banks</h2>
				<ul className={style.countries}>
					<li>Anguilla</li>
					<li> Belize</li>
					<li> British virgin Islands</li>
					<li> Dominica </li>
					<li>Duai</li> <li>Gibraltar</li>
					<li> Hong Kong</li>
					<li> Latvia</li>
					<li> Liechtenstein</li>
					<li> Marshall Islands</li> <li>Panama </li>
					<li>Scotland</li>
					<li> Seychelles</li>
					<li> Switzerland</li>
				</ul>
			</div>
			<div className={style.secondColumn}>
				<h2 className={style.littleHeader}>Dolor sit amet </h2>
				<ul className={style.countries}>
					<li>- Highest security standards</li> <li>- No credit score </li>
					<li>- Strict banking secrecy</li>{' '}
					<li>- Account currency optionally in EUR, USD, GBP, CHF or YEN </li>
					<li>- Direct bank transfer or via correspondent banks per IBAN and BIC </li>
					<li>- Asset management and diversified portfolio management</li>{' '}
					<li>- Direct Access to the worldwide SWIFT system</li>{' '}
					<li>- Access to online and phone banking</li> <li>- Debit and credit cards</li>
				</ul>
				<div className={style.warning}>
					{/* <img/> */}
					<p>Please also see the restrictions of this service.</p>
				</div>
			</div>
		</div>
	);
}

export default Services;
