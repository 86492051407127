import React from 'react';
import LetsTalk from './LetsTalk';
import Blog from "./BlogPreview";
import style from '../css/blogPost.module.scss';
import { Link } from 'react-router-dom';


function Post() {
	return (
		<div>
			<div className={style.headerImagePostFour}></div>
			<div className={style.container}>
				<div className={style.headerContainer}>
					<div className={style.header1}>
						<h1 className={style.header}>Five reasons to start an offshore company</h1>
						<p className={style.author}>Gediminas Butkus / 2020 03 16</p>
					</div>
					<div className={style.header2}>
					{/*<button className={style.shareButton}>Share This Post</button>*/}
					<Link to='/blog'>
					<button className={style.backButton}><p>Back to Blog</p></button>
					</Link>
					</div>
				</div>
				<div>
					<div className={style.text}>
	{/*					<h2 className={style.littleHeader}>

						</h2>*/}
						<p className={style.paragraph}>
						So you hear all about the tax havens and offshore jurisdictions, and perhaps you’re thinking about opening an offshore company yourself? The great news is that setting up a company offshore can offer a myriad of benefits if done by the book. In today’s post, I take a look at major reasons for starting an offshore company and highlight a few lesser-known things that everyone thinking about this option should consider.
						</p>
						<p className={style.paragraph}>
						<strong>Privacy</strong> I wouldn’t be surprised if I got an email saying: “Hi Gediminas, I am in need of an offshore entity and my goal is complete privacy”. Well, I would then ask you to define privacy first. You might be looking to stay away from an unstable political environment, or you might want to protect your identity from the public eye.
						</p>
						<p className={style.paragraph}>
						There are, of course, a million other reasons for laying low and everything can be done, yet everything has its price just as well. For instance, all banks to a lesser or greater extent exchange information with tax officers – whether that is a privacy breach depends on your perspective. What’s most important is that you learn the whole picture, before you decide on a solution that fits you best.
						</p>
						<p className={style.paragraph}>
						<strong>Tax purposes</strong> So, you’ve read all about the offshore tax havens with zero-tax policies and thought of reaping the benefits of the offshore scheme yourself – starting the company on a faraway island, paying 0% income or any other taxes.
						</p>
						<p className={style.paragraph}>
						Well, that could’ve been true ten years ago. However, those days are long gone. Nowadays, staying out of the tax man’s view is virtually impossible. Even if you go about and start a company in a tax-free jurisdiction, the income for that country might be taxed according to your local laws. The whole international tax world is, in fact, so complex, I would never recommend doing it on your own. In some cases, you could get yourself into real trouble, in a worst-case scenario – you might even face prison time.
						</p>
						<p className={style.paragraph}>
						So, an obvious piece of advice – find the right advisor that knows the ins and outs of international tax law. It’s essential to get everything right from the very beginning. An offshore entity can save you some tax money, but it has to be managed the right way.
						</p>
						<p className={style.paragraph}>
						<strong>No accounting</strong> Many offshore jurisdictions don’t require proactive bookkeeping. However, I would like to point out that if something sounds too good to be true, it usually is. For instance, there are no reporting requirements in jurisdictions such as Seychelles or the British Virgin Islands, meaning that you don’t need to keep an accounting book or prepare any kind of financial statements.
						</p>
						<p className={style.paragraph}>
						While that might sound great, remember that no bank would offer services to a company that could not provide any accounting information. You might not be burdened by the annual deadlines, however, you should still get someone to prepare all the necessary statements.
						</p>
						<p className={style.paragraph}>
						<strong>Fewer regulations</strong> In some instances, setting up the company in an offshore jurisdiction is the only way to go. Take, for example, the Forex trading platform. By way of example, let’s say you want to start a Forex Trading project in Belize. The local laws there require taking out a license for any forex-related business. In St. Vicent and the Grenadines, on the other hand, there are no licensing requirements for Forex trading, so a company is free to decide whether it wants to get licensed or rather allocate its resources elsewhere.
						</p>
						<p className={style.paragraph}>
						<strong>Nomad lifestyle</strong> Some people simply choose to live their lives as nomads – often defined as people without a fixed destination as their home as they move all around the globe. Due to extreme mobility, it is quite natural that nomads often opt for offshore accounts. In most situations, it is a matter of picking the right jurisdiction for their particular needs.
						</p>
						<p className={style.paragraph}>
						Finally, and as always, I’ll be delving into a real-life case.
						</p>
						<h2 className={style.littleHeader}>A case from the public</h2>
						<p className={style.paragraph}>
						<strong>Question:</strong> I have read you saying "I think it would be a much better idea to set up Dutch partnership (it is not subject to taxation as long as it does not carry any activities within the Netherlands)". Well, it’s the first time I’ve heard about it. Let’s say I start a Dutch partnership company that provides web development services. In such a case does receiving income from foreign customers exclusively would signify that I don’t carry activities within the Netherlands? And finally, how do tax institutions determine whether I carry out any activities within the Netherlands?
						</p>
						<p className={style.paragraph}>
						<strong>Our answer:</strong> As long as the Dutch partnership does not have an office, employees or a significant part of the customers in the Netherlands, it does not form an enterprise from the perspective of the Dutch tax system. According to the Dutch tax law, it is the partners of the partnership (not the partnership itself) that are subject to tax. If these partners are residents in a foreign country, the Dutch tax law does not apply.
						</p>
						<p className={style.paragraph}>
						From a practical perspective, the partner of the Dutch partnership might be a Cypriot company. Cyprus would consider the income of Dutch partnership as dividends. Dividends are exempt from taxes in Cyprus, and you might very well be the owner of that Cyprus company. Are you aware of the CFC rules in your country? The question is whether there are any laws within the country of your residency that could be applied to tax the income of your Cypriot company?
						</p>
					</div>
					{/*<div className={style.postImage}></div>
					<div className={style.text}>
						<p className={style.paragraph}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
							tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
							veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
							commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
							velit esse cillum dolore. Lorem ipsum dolor sit amet, consectetur
							adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
							magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
							laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
							in reprehenderit in voluptate velit esse cillum dolore.{' '}
						</p>
						<p className={style.paragraph}>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
							tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
							veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
							commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
							velit esse cillum dolore. Lorem ipsum dolor sit amet, consectetur
							adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
							magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
							laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
							in reprehenderit in voluptate velit esse cillum dolore. Lorem ipsum
							dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
							incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
							quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
							consequat. Duis aute irure dolor in reprehenderit in voluptate velit
							esse cillum dolore. Lorem ipsum dolor sit amet, consectetur adipiscing
							elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
							Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
							ut aliquip ex ea commodo consequat. Duis aute irure dolor in
							reprehenderit in voluptate velit esse cillum dolore.
						</p>
					</div>*/}
				</div>
			</div>
			<h1 className={style.header}>Also you may like this</h1>
			<Blog />
			<LetsTalk />
		</div>
	);
}

export default Post;
