import React, { Component } from 'react';
import LetsTalk from './LetsTalk';
import BlogPostOne from './BlogPostOne';
import BlogPostTwo from './BlogPostTwo';
import BlogPostThree from './BlogPostThree';
import BlogPostFour from './BlogPostFour';
import {Helmet} from "react-helmet";


import style from '../css/blog.module.scss';

class Blog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 1,
			pages: 3
		};
	}

	render() {
		return (
			<div>
				<BlogPage page={this.state.page} pages={this.state.pages} />
				<LetsTalk />
			</div>
		);
	}
}

function BlogPage(props) {
	return (
		<div>
		<div className="application">
		<Helmet>
				<meta charSet="utf-8" />
				<title>Offshore Bank Accounts</title>
				<meta name="description" content="Opening offshore bank accounts and helping offshore companies to get a bank account. Also assisting with legal structuring and second citizenships." />
				<link rel="canonical" href="https://offshorebankaccounts.com" />
				<meta property="og:locale" content="en_US" />
				<meta property="og:type" content="website" />
				<meta property="og:title" content="Offshore Bank Accounts" />
				<meta property="og:description" content="Opening offshore bank accounts for businesses and individuals" />
				<meta property="og:url" content="https://offshorebankaccounts.com" />
				<meta property="og:site_name" content="Offshore Bank Accounts" />
				<meta property="og:image" content="https://offshorebankaccounts.com/assets/offshore-bank-og.png" />
				<meta property="og:image:secure_url" content="https://offshorebankaccounts.com/assets/offshore-bank-og.png" />
				<meta property="og:image:width" content="1280" />
				<meta property="og:image:height" content="720" />
		</Helmet>
		</div>
			<h1 className={style.header}>Blog</h1>
			<div className={style.container}>
				<BlogPostFour />
				<BlogPostThree />
				<BlogPostTwo />
				<BlogPostOne />
			</div>
			{/*<PageNumber page={props.page} pages={props.pages} />*/}
		</div>
	);
}

class PageNumber extends Component {
	renderNumbers = () => {
		let pageNumbers = '';
		let i = 1;
		for (i; i <= this.props.pages; i++) {
			pageNumbers += this.renderNumber(i, this.props.page === i);
		}
		return pageNumbers;
	};

	renderNumber (number, isCurrentPage){
		if(isCurrentPage)return( <div className={style.selected}>{number}</div>);
		return (<div>{number}</div>);
	}

	render() {
		return <div>{this.renderNumbers()}</div>;
	}
}

export default Blog;
