import React from "react";
import LetsTalk from "./LetsTalk";
import style from '../css/serviceForCountry.module.scss';

function ServiceForCountry() {
	return (
		<div>
			<Country/>
			<LetsTalk/>
		</div>
	);
}

function Country() {
	return (
		<div className={style.container}>
			<div className={style.firstColumn}>
				<h1 className={style.header}>Service for Anguilla</h1>
				<button className={style.button}>Order Your Company in Anguilla</button>
			</div>
			<div className={style.text}>
				<h2 className={style.littleHeader}>Dolor sit amet</h2>
				<ul className={style.paragraph}>
					<li>- Your company in Latvia, European Union </li><li>- A dedicated client relationship manager </li><li>- EU company with 15% corporate income tax </li><li>- Guaranteed bank account, including e-Banking and credit card </li><li>- Basic establishment fee: 1,550 USD</li>
				</ul>
				<div className={style.warning}>
					<h3>Company information:</h3>
					<p>Latvia Company Features </p><p>Fee Schedule </p><p>Jurisdiction Comparison Chart (Excel)</p>
				</div>
				<p className={style.paragraph}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.</p>
				<p className={style.paragraph}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.</p>
				<h2 className={style.littleHeader}>Main advantages:</h2>
				<ul className={style.paragraph}>
					<li>- High reputation </li><li>- EU company </li><li>- 15% corporate income tax, one of the lowest in the EU </li><li>- Minimum capital payable upon incorporation: 2,800 USD</li>
				</ul>
				<h2 className={style.littleHeader}>Costs of Lorem Ipsum company:</h2>
				<p className={style.paragraph}>Basic establishment fee: 1,550 USD</p>
				<div className={style.warning}>
					<p>Considering the legal and fiscal structural options as well as the high reputation, we recommend the offshore company formation in the United Arab Emirates. </p>
				</div>
			</div>
		</div>
	);
}

export default ServiceForCountry;
