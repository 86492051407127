import React from 'react';
import {Link} from "react-router-dom";
import blog from '../css/blogPreview.module.scss';

function BlogPostPreview() {
	return (
		<div className={blog.hover}>
			<div className={blog.blogPostContainer}>
				 <Link to="/blog/why-you-might-need-an-offshore-bank-account"><img src={require('./../assets/Offshore-bank-small.jpg')} height='100%' width='100%' align="middle"/>
				{/*<p className={blog.paragraph}>Image</p>*/}
				<h2 className={blog.littleHeader}>Why you might need an offshore bank account?</h2>
				<p className={blog.paragraph}>
					Several reasons, including ones you probably have never though about,why you might need an offshore bank account.
				</p></Link>
			</div>
		</div>
	);
}
export default BlogPostPreview;
