import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import SocialMediaLinks from './SocialMediaLinks';
import { ReactComponent as ObcLogo } from '../assets/obclogo.svg';
import { ReactComponent as MenuButton } from '../assets/menu.svg';
import { ReactComponent as X } from '../assets/close.svg';
import style from '../css/navigation.module.scss';

import { ReactComponent as Arrow } from '../assets/dropdown.svg';

function Navigation(props) {
	return (

		<div className={style.container}>
			<div className={style.browser}>
				<Logo />
				<Menu />
		<div className={style.socialMedia}>
					<SocialMediaLinks />
				</div>
				{/*<LanguageSelect
					switchLanguage={props.switchLanguage}
					languageSelected={props.languageSelected}
				/>*/}
			</div>
			<div className={style.tablet}>
				<Logo />
<div className={style.socialMedia}>
					<SocialMediaLinks />
				</div>
					{/*	<LanguageSelect
					switchLanguage={props.switchLanguage}
					languageSelected={props.languageSelected}
				/>*/}
				<MenuBurger />
			</div>
			<div className={style.mobile}>
		{/*<LanguageSelect
					switchLanguage={props.switchLanguage}
					languageSelected={props.languageSelected}
				/>*/}
				<Logo />
		<MenuBurger />
			</div>
		</div>
	);
}

function Logo() {
	return (
		<div className={style.logo}>
				<div className="application">

<Helmet
	script={[
		{
			type: 'text/javascript',
			innerHTML: `
				window.__lc = window.__lc || {}; window.__lc.license = 11794131; (function() { var lc = document.createElement('script'); lc.type = 'text/javascript'; lc.async = true; lc.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(lc, s); })();
			`
		}
	]}
	noscript={[
		{
			type: 'text/javascript',
			innerHTML: `
				<a href="https://www.livechatinc.com/chat-with/11794131/" rel="nofollow">Chat with us</a>, powered by <a href="https://www.livechatinc.com/?welcome" rel="noopener nofollow" target="_blank">LiveChat</a>
			`
		}
	]}
>
</Helmet>
</div>

			<Link to="/">
				<ObcLogo />
			</Link>
		</div>
	);
}

class MenuBurger extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menuOpen: false
		};
	}

	openMenu = () => {
		this.setState({ menuOpen: true });
	};

	closeMenu = () => {
		this.setState({ menuOpen: false });
	};

	render() {
		return (
			<div className={style.center}>
				{this.state.menuOpen ? (
					<div className={style.openMenu}>
						<div onClick={() => this.closeMenu()} className={style.x}>
							<X />

						</div>
						<Menu closeMenu={this.closeMenu} />
					{/*	<div className={style.socialMedia}>
							<SocialMediaLinks />
						</div>*/}
					</div>
				) : (
					<div className={style.menuButton} onClick={() => this.openMenu()}>
						<MenuButton />
					</div>
				)}
			</div>
		);
	}
}

function Menu(props) {
	return (
		<div className={style.buttons}>
		{/*	<Link to="/aboutUs">
				<p
					className={style.button}
					onClick={() => {
						if (typeof props.closeMenu === 'function') {
							props.closeMenu();
						}
					}}
				>
					<FormattedMessage id="buttonAboutUs" />
				</p>
			</Link>
			<Link to="/services">
				<p
					className={style.button}
					onClick={() => {
						if (typeof props.closeMenu === 'function') {
							props.closeMenu();
						}
					}}
				>
					<FormattedMessage id="buttonServices" />
				</p>
			</Link> */}
			<Link to="/">
				<p
					className={style.button}
					onClick={() => {
						if (typeof props.closeMenu === 'function') {
							props.closeMenu();
						}
					}}
				>
					<FormattedMessage id="buttonHome" />
				</p>
			</Link>
			<Link to="/#solutions">
				<a href={"/#solutions"}><p
					className={style.button}
					onClick={() => {
						if (typeof props.closeMenu === 'function') {
							props.closeMenu();
						}
					}}
				>
					<FormattedMessage id="buttonSolutions" />
				</p></a>
			</Link>
			<Link to="/blog">
				<p
					className={style.button}
					onClick={() => {
						if (typeof props.closeMenu === 'function') {
							props.closeMenu();
						}
					}}
				>
					<FormattedMessage id="buttonBlog" />
				</p>
			</Link>
			<Link to="/contact">
				<p
					className={style.button}
					onClick={() => {
						if (typeof props.closeMenu === 'function') {
							props.closeMenu();
						}
					}}
				>
					<FormattedMessage id="buttonContact" />
				</p>
			</Link>

		</div>
	);
}

class LanguageSelect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dropdownOpen: false
		};
	}

	showDropdown = () => {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	};

	render() {
		return (
			<div className={style.select}>
				<div onClick={this.showDropdown} className={style.dropDownButton}>
					<div className={style.inline}>
						{this.props.languageSelected}
						<Arrow />
					</div>
				</div>
				{this.state.dropdownOpen ? (
					<div id="dropdown" className={style.dropDown}>
						{this.props.languageSelected !== 'en' ? (
							<p
								onClick={() => {
									this.props.switchLanguage('en');
									this.showDropdown();
								}}
							>
								En
							</p>
						) : null}
						{this.props.languageSelected !== 'lt' ? (
							<p
								onClick={() => {
									this.props.switchLanguage('lt');
									this.showDropdown();
								}}
							>
								Lt
							</p>
						) : null}
					</div>
				) : null}
			</div>
		);
	}
}

export default Navigation;
